import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CalendarFill from '../../../assets/img/icons/CalendarFill'
import Calendar from 'react-calendar'
import { Tooltip } from 'react-tooltip'
import moment from 'moment'
import { uzLocale } from '../../../pages/teachers-pages/teacher-home/component/monthly-slot'

type CategoryInput = {
  name: string
  label: string
  control: Control
  error: any
}

const CustomDateInput: React.FC<CategoryInput> = ({ name, label, control, error }) => {
  const { t, i18n } = useTranslation()

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field }) => (
        <div className='login-form__item'>
          <div className='login-form__name'>{t(label)}</div>
          <div className='login-form__input'>
            <input type='date' value={field.value} onChange={e => field.onChange(e.target.value)} />
            <a data-tooltip-id='calendar' data-some-relevant-attr={'start_date'}>
              <CalendarFill />
            </a>
          </div>
          {error?.message && <div className='login-form__advice'>{t(String(error?.message))}</div>}
          <Tooltip
            id='calendar'
            place='bottom'
            offset={20}
            style={{
              backgroundColor: 'white',
              color: 'black',
              boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
              zIndex: 9999
            }}
            clickable={true}
            openOnClick={true}
            render={() => {
              return (
                <Calendar
                  onChange={date => field.onChange(moment(date?.toString()).format('YYYY-MM-DD'))}
                  value={moment(field.value).toDate()}
                  locale={i18n.language}
                  // Oyning nomini chiqarish
                  formatMonthYear={
                    i18n?.language === 'uz'
                      ? (locale, date) => `${uzLocale.months[date.getMonth()]} ${date.getFullYear()}`
                      : undefined
                  }
                  formatMonth={
                    i18n?.language === 'uz' ? (locale, date) => `${uzLocale.months[date.getMonth()]}` : undefined
                  }
                  // Hafta kunlarini qisqa shaklda chiqarish (Ya, Du, Se...)
                  formatShortWeekday={
                    i18n?.language === 'uz' ? (locale, date) => uzLocale.weekdaysShort[date.getDay()] : undefined
                  }
                  // Hafta kunlarini to'liq yozish (Yakshanba, Dushanba...)
                  formatWeekday={
                    i18n?.language === 'uz' ? (locale, date) => uzLocale.weekdaysLong[date.getDay()] : undefined
                  }
                />
              )
            }}
          />
        </div>
      )}
    />
  )
}

export default CustomDateInput
