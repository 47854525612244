// @ts-nocheck

import React, { useContext, useMemo, useState } from 'react'
import moment from 'moment'
import { Tooltip } from 'react-tooltip'
import LessonInfo from '../lesson-info'
import DailySlots from '../daily-slots'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../../../../configs/request'
import { RandomContext } from '../../../../../providers/RandomProvider'
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar'
import { format, parse, startOfWeek, getDay } from 'date-fns'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import uzLocale from 'date-fns/locale/uz'
import ruLocale from 'date-fns/locale/ru'
import enLocale from 'date-fns/locale/en-US'

// Tanlangan tilni o'zgartirish
const locales = {
  uz: uzLocale,
  ru: ruLocale,
  en: enLocale
}
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
})

const MyEventWrapper: React.FC<any> = eventWrapperProps => (
  <a
    data-tooltip-id={eventWrapperProps?.event?.schedule ? 'my-tooltip' : ''}
    onClick={() => console.log(eventWrapperProps)}
    data-some-relevant-attr={eventWrapperProps?.event?.id}
  >
    {eventWrapperProps.children}
  </a>
)
const DateCellWrapper: React.FC<any> = dateCellWrapperProps => {
  const { t } = useTranslation()
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const day = new Date(dateCellWrapperProps.value)
  const isEditable = useMemo(() => {
    const minDate = moment().add(1, 'days')

    // Bugungi sana + 2 oy
    const maxDate = moment().add(2, 'months')

    if (moment(dateCellWrapperProps.value).isBetween(minDate, maxDate, 'day', '[]')) {
      return true
    } else {
      return false
    }
  }, [dateCellWrapperProps.value])

  const style = {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    height: '15%',
    backgroundColor: '#FB9851',
    borderRadius: 5,
    cursor: 'pointer',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'white',
    zIndex: 999
  }
  return (
    <div style={{ position: 'relative', flex: 1, backgroundColor: 'white', borderRadius: 10 }}>
      {dateCellWrapperProps.children}
      {isEditable && (
        <a
          style={style}
          onClick={() => console.log(dateCellWrapperProps.value)}
          data-tooltip-id='date-cell-wrapper'
          data-some-relevant-attr={moment(dateCellWrapperProps.value).format('YYYY-MM-DD')}
        >
          {t('Change time')}
        </a>
      )}
    </div>
  )
}

const MyCalendar = (props: any) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const { random } = useContext(RandomContext)
  const [open, setOpen] = useState(false)
  const { t, i18n } = useTranslation()

  // +5 more events handler
  const handleDrillDown = (date: Date, view: string) => {
    if (view === 'month') {
      return false
    }
    return true
  }

  const { data } = useQuery({
    queryKey: [moment(currentDate)?.format('YYYY-MM'), random],
    queryFn: async () => {
      const response = await request({
        url: 'teacher/slots/monthly',
        params: { date: moment(currentDate)?.format('YYYY-MM') },
        method: 'GET'
      })
      return response?.data
    }
  })

  const events = useMemo(() => {
    if (data?.data) {
      return data?.data?.map(item => {
        return {
          title: item?.schedule ? `${item?.time} - ${item?.schedule?.lesson?.name?.[i18n.language]}` : item.time,
          start: moment(item?.date).toDate(),
          end: moment(item?.date).toDate(),
          id: item?.id,
          schedule: item?.schedule
        }
      })
    } else return []
  }, [data, i18n.language])

  const handlePrevMonth = () => {
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    setCurrentDate(prevMonth)
  }

  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    setCurrentDate(nextMonth)
  }

  const formatCurrentMonth = (date: any) => {
    return `${t(moment(date).format('MMMM'))} ${moment(date).format('YYYY')}` // Формат: Название месяца Год
  }

  return (
    <>
      <div className='calendar-main'>
        <div className='calendar-head'>
          <div className='calendar-title'>{t('Schedule')}</div>
          <div className='calendar-head__wrap'>
            <div className='calendar-head__date'>{formatCurrentMonth(currentDate)}</div>
            <div className='calendar-head__today'>{t('Today')}</div>
            <div className='calendar-head__arrows'>
              <div className='arrows'>
                <span className='arrow-left' onClick={handlePrevMonth}>
                  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M14 8L10 12L14 16'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
                <span className='arrow-right' onClick={handleNextMonth}>
                  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M10 16L14 12L10 8'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='calendar-wrap'>
        <div className='myCustomHeight'>
          <BigCalendar
            localizer={localizer}
            culture={i18n.language}
            events={events}
            startAccessor='start'
            endAccessor='end'
            popup
            {...props}
            toolbar={false}
            onDrillDown={handleDrillDown}
            components={{
              eventWrapper: MyEventWrapper,
              dateCellWrapper: DateCellWrapper,
              eventContainerWrapper: DateCellWrapper,
              dayColumnWrapper: DateCellWrapper,
              backgroundEventWrapper: DateCellWrapper
            }}
            date={currentDate} // Установите текущую дату для календаря
            onNavigate={(newDate: any) => setCurrentDate(newDate)} // Обновите состояние при навигации
          />
        </div>
      </div>

      <Tooltip
        id='my-tooltip'
        style={{
          backgroundColor: 'white',
          color: 'black',
          boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
          zIndex: 9999
        }}
        place='bottom'
        clickable={true}
        openOnClick={true}
        render={({ content, activeAnchor }) => {
          return <LessonInfo id={activeAnchor?.getAttribute('data-some-relevant-attr') as string} />
        }}
      />

      <Tooltip
        id='date-cell-wrapper'
        place='bottom'
        isOpen={open}
        openOnClick
        setIsOpen={setOpen}
        offset={1}
        style={{
          backgroundColor: 'white',
          color: 'black',
          boxShadow: `0px 15px 50px 0px rgba(0, 0, 0, 0.08)`,
          zIndex: 999,
          padding: 0,
          opacity: 1,
          borderRadius: '1rem'
        }}
        clickable={true}
        // openOnClick={true}
        render={({ content, activeAnchor }) => {
          return (
            <DailySlots
              onSuccess={() => {
                setOpen(false)
                props.openModal()
              }}
              date={activeAnchor?.getAttribute('data-some-relevant-attr') as string}
            />
          )
        }}
      />
    </>
  )
}

export default MyCalendar
