import React, { Fragment, useContext } from 'react'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import 'swiper/swiper-bundle.css'
import GetContainer from '../../../../../components/get-container'
import { ISubject } from '../../../../../types/types'
import { MEDIA_URL } from '../../../../../configs/request'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../context/AuthContext'
import { SwiperSlide, Swiper, useSwiper } from 'swiper/react'
import { SwiperButtonNext, SwiperButtonPrev } from './SwiperButtons'

export const TaklifSlider: React.FC = () => {
  const { i18n, t } = useTranslation()

  const { user } = useContext(AuthContext)
  const swiperOptions = {
    spaceBetween: 10,
    slidesPerView: 4.12,
    // centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: '.taklif-right',
      prevEl: '.taklif-left'
    },

    // autoplay: {
    //   delay: 20000, // автоматическая смена слайдов каждые 5 секунд
    // },
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        freeMode: false
      },
      768: {
        slidesPerView: 4.12,
        slidesPerGroup: 1,
        spaceBetween: 10,
        freeMode: false
      }
    }
  }

  const swiper = useSwiper()

  return (
    <div className='home-recomended'>
      {/* Swiper */}

      <GetContainer url='student/subjects' params={{ type: 'individual' }}>
        {({ data }) => (
          <Swiper
            // install Swiper modules
            modules={[Navigation, A11y, Pagination, Scrollbar]}
            breakpoints={swiperOptions.breakpoints}
            // navigation
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            slidesPerView={swiperOptions.slidesPerView}
            spaceBetween={swiperOptions.spaceBetween}
            loop={swiperOptions.loop}
            onSwiper={(swiper: any) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            className='home-recomended__carousel'
          >
            <span slot='container-start'>
              <div className='home-recent__head'>
                <div className='home-recent__title'>{t('Classes offered')}</div>
                <div className='arrows'>
                  <SwiperButtonPrev>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M14 8L10 12L14 16'
                        stroke='currentColor'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </SwiperButtonPrev>
                  <SwiperButtonNext>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M10 16L14 12L10 8'
                        stroke='currentColor'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </SwiperButtonNext>
                </div>
              </div>
            </span>

            {data?.data?.map((item: ISubject) => (
              <SwiperSlide className='lesson' key={item?.id + item?.created_at}>
                {/* <span className='lesson__bookmark'>
                  <img src='img/icons/bookmark.svg' alt='ico' />
                </span> */}
                <div className='lesson__img'>
                  <img src={MEDIA_URL + item?.banner} alt='lesson' />
                </div>
                <div className='lesson-info'>
                  <div className='lesson__name'>{item?.name?.[i18n.language]}</div>
                  <div className='lesson__text'>{item?.title?.[i18n.language]}</div>
                  {item?.options?.map(option => (
                    <div className='lesson__wrap' key={option.id + option?.created_at}>
                      <div className='lesson__time'>
                        <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 
						22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM15.75 11.25C16.16 11.25 16.5 11.59 16.5 12C16.5 12.41 16.16 12.75 15.75 12.75H8.5V13C8.5 14.24 9.51 15.25 10.75 15.25H15.75C16.16 15.25 16.5 15.59 16.5 16C16.5 16.41 16.16 16.75 15.75 16.75H10.75C8.68 16.75 7 15.07 7 13V11C7 8.93 8.68 7.25 10.75 7.25H15.75C16.16 7.25 16.5 7.59 16.5 8C16.5 8.41 16.16 8.75 15.75 8.75H10.75C9.51 8.75 8.5 9.76 8.5 11V11.25H15.75Z'
                            fill='#868991'
                          />
                        </svg>
                        <span>
                          {option?.price} {t('Coin')}
                        </span>
                      </div>
                      <div className='lesson__time'>
                        <svg width={22} height={22} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M20.1673 10.9997C20.1673 16.0597 16.0607 20.1663 11.0007 20.1663C5.94065 20.1663 1.83398 16.0597 1.83398 10.9997C1.83398 5.93967 5.94065 1.83301 11.0007 1.83301C16.0607 1.83301 20.1673 5.93967 20.1673 10.9997Z'
                            stroke='#868991'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M14.4002 13.9146L11.5585 12.2188C11.0635 11.9255 10.6602 11.2196 10.6602 10.6421V6.88379'
                            stroke='#868991'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span>
                          {option?.duration} {t('minutes')}
                        </span>
                      </div>
                    </div>
                  ))}
                  <a href={`/${user?.role}/school/courses/${item?.slug}`} className='lesson__btn btn btn-trans'>
                    {t('More')}
                  </a>
                  <a href='#' className='lesson__link' />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </GetContainer>
    </div>
  )
}
