import { useParams, useSearchParams } from 'react-router-dom'
import GetContainer from '../../../components/get-container'
import { IReport, ISchedule, IStudentHomework } from '../../../types/types'
import { useTranslation } from 'react-i18next'
import IndividualCompleted from './components/IndividualCompleted'
import GroupCompleted from './components/GroupCompleted'
import { MEDIA_URL } from '../../../configs/request'
import moment from 'moment'
import WithHTML from '../../../components/with-html'
import GetContainerPagination from '../../../components/get-container-pagination'

export const ClassesSingle = () => {
  const { id } = useParams()
  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  return (
    <GetContainer url={'/teacher/group_schedules/' + id}>
      {({ data }: { data: { data: ISchedule } }) => (
        <div className='classes-single'>
          <h3 className='classes-single__title' style={{ marginBottom: '1rem' }}>
            {t('Students')}:{' '}
            <span>
              {data?.data?.group?.students?.map(student => (
                <a href={'/teacher/students/' + student?.uuid} key={student?.uuid}>
                  {`${student?.firstname} ${student?.lastname}`} |{' '}
                </a>
              ))}
            </span>
          </h3>
          <div className='classes-single__top'>
            <h3 className='classes-single__title'>
              {t('Subject')}: <span>{data?.data?.group?.subject?.name?.[i18n.language]}</span>
            </h3>
            <p className='classes-single__date'>
              <span>{t('Date')}:</span> {moment(data?.data?.date).format('DD.MM.YYYY')} {' ' + data?.data?.time}
            </p>
          </div>

          <div className='homework-wrap'>
            <div className='homework-main'>
              <h4 className='homework__title'>
                <span>{t('Lesson&Name')}:</span> {data?.data?.lesson?.name?.[i18n.language]}
              </h4>
              <div className='classes-single__title'></div>
              <ul className='homework-info'>
                <li>
                  {t('Grade')}: <span>{data?.data?.lesson?.unit?.grade?.name?.[i18n?.language]}</span>
                </li>
                <li>
                  {t('Unit')}: <span>{data?.data?.lesson?.unit?.name?.[i18n?.language]}</span>
                </li>
                <li>
                  {t('Lesson')}: <span>{data?.data?.lesson?.name?.[i18n.language]}</span>
                </li>
              </ul>
              <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <WithHTML html={data?.data?.lesson?.lesson_plan?.[i18n.language]} />
              </div>
              {searchParams.get('type') === 'complete' ? (
                !!data?.data ? (
                  data?.data?.group?.type === 'individual' ? (
                    <IndividualCompleted data={data?.data} />
                  ) : (
                    <GroupCompleted data={data?.data} />
                  )
                ) : null
              ) : null}
            </div>

            <div className='homework-side'>
              {/*<div className='homework-side__head'>*/}
              {/*  <div className='homework-side__name'>Hana Sato (8 years old, female)</div>*/}
              {/*  <span className='homework-side__add'>*/}
              {/*    <svg width={12} height={12} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>*/}
              {/*      <path d='M6 1V11' stroke='#F5F6FA' strokeWidth={2} strokeLinecap='round' />*/}
              {/*      <path d='M11 6L1 6' stroke='#F5F6FA' strokeWidth={2} strokeLinecap='round' />*/}
              {/*    </svg>*/}
              {/*  </span>*/}
              {/*</div>*/}
              <GetContainerPagination
                url={data?.data?.group_id ? `/teacher/groups/${data?.data?.group_id}/reports` : ''}
              >
                {({ data: reports }) => (
                  <div className='homework-reviews'>
                    {reports?.data?.map((report: IReport) => (
                      <div className='homework-card' key={report?.id} style={{ marginBottom: '1rem' }}>
                        <div className='homework-card__head'>
                          <img
                            src={
                              report?.teacher?.photo
                                ? MEDIA_URL + report?.teacher?.photo
                                : require('../../../assets/img/mock/student.jpg')
                            }
                            alt='student'
                          />
                          <span>{`${report?.teacher?.firstname} ${report?.teacher?.lastname} ${t('at')} ${moment(
                            report?.created_at
                          ).format('D MMM HH:mm')}`}</span>
                        </div>
                        <div className='homework-card__text'>{report?.report}</div>
                      </div>
                    ))}
                  </div>
                )}
              </GetContainerPagination>
              <GetContainer url={`teacher/group_schedules/${id}/homework`}>
                {({ data }: { data: { data: IStudentHomework[] } }) => (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} className='homework-container'>
                    <h3 className='classes-single__title'>{t('Homeworks&Complete')}</h3>
                    <div className='homework-row'>
                      {data?.data
                        ?.filter(homework => !!homework.file)
                        ?.map(homework => (
                          <a
                            href={MEDIA_URL + homework?.file}
                            target='_blank'
                            rel='noreferrer'
                            className='homework-complete'
                          >
                            <span>{`${homework?.student?.firstname} ${homework?.student?.lastname}`}</span>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M9 11V17L11 15M9 17L7 15'
                                stroke='#1F4F51'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14'
                                stroke='#1F4F51'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M22 10H18C15 10 14 9 14 6V2L22 10Z'
                                stroke='#1F4F51'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </svg>
                          </a>
                        ))}
                    </div>
                  </div>
                )}
              </GetContainer>
            </div>
          </div>
        </div>
      )}
    </GetContainer>
  )
}
