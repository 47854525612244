import React, { Fragment, useState } from 'react'
import { ISchedule } from '../../../../types/types'
import Form from '../../../../components/form'
import * as Yup from 'yup'
import CustomCheckbox from '../../../../components/form/custom-checkbox'
import { useTranslation } from 'react-i18next'
import CustomInput from '../../../../components/form/custom-input'
import CustomTextarea from '../../../../components/form/custom-textarea'
import { Control, useFieldArray } from 'react-hook-form'
import RequestModal from '../../../../components/request-modal'
import { useNavigate } from 'react-router-dom'

const validationSchema = Yup.object().shape({
  schedule_id: Yup.number().required('Phone is required'), //required
  reviews: Yup.array(
    Yup.object().shape({
      student_id: Yup.number().required('Phone is required'), //required
      visit: Yup.boolean().required('Phone is required'), //required
      message: Yup.string().required('Phone is required') //required
    })
  ),
  report: Yup.string().required('Phone is required') //required
})

interface IProps {
  data: ISchedule
}

interface IReviews {
  control: Control
  errors: any
  name: string
  data: ISchedule
  watch: any
}

const Reviews = (props: IReviews) => {
  const { t } = useTranslation()
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control: props.control, // control props comes from useForm (optional: if you are using FormProvider)
    name: props.name // unique name for your Field Array
  })
  return (
    <Fragment>
      {fields?.map((item, index) => (
        <Fragment key={item?.id}>
          <div className='homework-other__item'>
            <div className='homework__title'>{`${props.data?.group?.students?.[index]?.firstname} ${props.data?.group?.students?.[index]?.lastname}`}</div>
            <div className='homework-other__wrap'>
              <CustomCheckbox
                control={props.control}
                name={`${props.name}[${index}].visit`}
                label='Visit'
                error={props.errors?.[props.name]?.[index].visit}
              />
            </div>
          </div>
          <div className='homework-other__item'>
            <div className='homework__title'>{t('Review')}</div>
            <div className='homework-other__wrap'>
              <div className='homework__text'>
                {t(
                  'This message will get sent to your student by email. Please write your message in complete sentences. Please do not comment anything negative, encourage your student. Double check your spelling before submitting.'
                )}
              </div>
              <div className='homework__input'>
                <CustomInput
                  control={props.control}
                  name={`${props.name}[${index}].message`}
                  label=''
                  error={props.errors?.[props.name]?.[index].message}
                  disabled={!props.watch(`${props.name}[${index}].visit`)}
                  placeholder={t('Did well with')}
                />
              </div>
            </div>
          </div>
        </Fragment>
      ))}
    </Fragment>
  )
}

function GroupCompleted(props: IProps) {
  const { t } = useTranslation()
  const renderValues = (data: ISchedule) => {
    return {
      schedule_id: data?.id, //required
      reviews: data?.group?.students?.map(student => {
        return {
          student_id: student?.id, //required
          message: '', //nullable (if visit == true -> message:required)
          visit: true //required
        }
      }),
      report: '' //required
    }
  }
  const [modal, setModal] = useState({
    open: false,
    isError: false,
    isSuccess: false,
    error: null
  })
  const navigate = useNavigate()
  return (
    <Fragment>
      <Form
        validationSchema={validationSchema}
        initialValues={renderValues(props.data)}
        url='/teacher/group_schedules/group/completed'
        method='POST'
        onSuccess={() => setModal({ isSuccess: true, isError: false, error: null, open: true })}
        onError={error => setModal({ isSuccess: false, isError: true, error: error, open: true })}
      >
        {({ form, handleFinish }) => {
          const {
            control,
            handleSubmit,
            formState: { errors },
            setValue,
            watch
          } = form
          return (
            <div style={{ marginTop: '1rem' }}>
              <div className='homework-other'>
                <Reviews control={control} errors={errors} name='reviews' watch={watch} data={props.data} />
                <div className='homework-other__item'>
                  <div className='homework__title'>{t('Report')}</div>
                  <div className='homework-other__wrap' style={{ flex: 1 }}>
                    <div className='homework__input'>
                      <CustomTextarea
                        control={control}
                        name='report'
                        label='Report'
                        error={errors?.review}
                        placeholder={'Report'}
                      />
                    </div>
                  </div>
                </div>
                <div className='homework-other__item'>
                  <div className='homework__title'></div>
                  <div className='homework-other__wrap'>
                    <button className='homework__btn btn' onClick={handleSubmit(data => handleFinish(data))}>
                      {t('Submit')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Form>
      <RequestModal
        isOpen={modal?.open}
        close={() => {
          setModal({ open: false, isError: false, isSuccess: false, error: null })
          navigate(-1)
        }}
        title='Request subjects title'
        error={modal?.error}
        isSuccess={modal.isSuccess}
        description={
          <>
            {/* <p>
                    {data?.data
                      ?.filter((subject: ISubject) =>
                        form.watch('subject_ids')?.some((id: number) => id === subject?.id)
                      )
                      ?.map((subject: ISubject) => subject?.name?.[i18n.language])
                      .join(', ')}
                  </p> */}
          </>
        }
        send={() => {}}
        acceptBtnText=''
        cancelBtnText=''
        successTitle='Request group complete success title'
        successDesc='Request group complete success desc'
        errorTitle='Request group complete success title'
      />
    </Fragment>
  )
}

export default GroupCompleted
