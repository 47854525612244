//@ts-nocheck
import React, { Fragment, useContext, useState } from 'react'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import Calendar from 'react-calendar'
import GetContainer from '../../../../../components/get-container'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import { useCustomMutation } from '../../../../../hooks/useCustomMutation'
import RequestModal from '../../../../../components/request-modal'
import { ISubject } from '../../../../../types/types'
import { RandomContext } from '../../../../../providers/RandomProvider'
import { isAfterDate } from '../../../../../configs/helpers'
import { useAuth } from '../../../../../hooks/useAuth'
import { uzLocale } from '../../../../teachers-pages/teacher-home/component/monthly-slot'

interface TeacherCalendarProps {
  teacherId: string
  subject?: ISubject
}

const TeacherCalendar: React.FC<TeacherCalendarProps> = ({ teacherId, subject }) => {
  const { i18n, t } = useTranslation()
  const [month, setMonth] = useState(new Date())
  const [day, setDay] = useState(new Date())
  const [slot, setSlot] = useState(null)
  const [open, setOpen] = useState(false)
  const { random, setRandom } = useContext(RandomContext)
  const { refresh } = useAuth()
  const { mutate, isSuccess, error } = useCustomMutation('/student/slots/reserve', 'POST', slot)

  return (
    <GetContainer
      hideLoading
      url={`/student/teachers/${teacherId}/monthly/slot/?date=${moment(month).format('YYYY-MM')}`}
    >
      {({ data }) => (
        <Fragment>
          <a data-tooltip-id={`teacher-calendar-${subject?.id}`}>
            <Calendar
              onChange={selected => {
                if (data?.data?.dates?.[moment(selected).format('YYYY-MM-DD')] !== undefined) {
                  setDay(selected)
                  setOpen(true)
                } else {
                  setOpen(false)
                }
              }}
              onActiveStartDateChange={({ action, activeStartDate, value, view }) => setMonth(activeStartDate)}
              locale={i18n.language}
              tileClassName={({ activeStartDate, date, view }) =>
                view === 'month'
                  ? data?.data?.dates?.[moment(date).format('YYYY-MM-DD')] === true
                    ? 'greenDay'
                    : data?.data?.dates?.[moment(date).format('YYYY-MM-DD')] === false
                    ? 'redDay'
                    : null
                  : null
              }
              showNeighboringMonth={false}
              // locale={i18n.language}
              // Oyning nomini chiqarish
              formatMonthYear={
                i18n?.language === 'uz'
                  ? (locale, date) => `${uzLocale.months[date.getMonth()]} ${date.getFullYear()}`
                  : undefined
              }
              formatMonth={
                i18n?.language === 'uz' ? (locale, date) => `${uzLocale.months[date.getMonth()]}` : undefined
              }
              // Hafta kunlarini qisqa shaklda chiqarish (Ya, Du, Se...)
              formatShortWeekday={
                i18n?.language === 'uz' ? (locale, date) => uzLocale.weekdaysShort[date.getDay()] : undefined
              }
              // Hafta kunlarini to'liq yozish (Yakshanba, Dushanba...)
              formatWeekday={
                i18n?.language === 'uz' ? (locale, date) => uzLocale.weekdaysLong[date.getDay()] : undefined
              }
            />
          </a>
          {!!subject && (
            <Tooltip
              id={`teacher-calendar-${subject?.id}`}
              style={{
                backgroundColor: 'white',
                color: 'black',
                boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
                width: 'auto',
                height: 'auto',
                zIndex: 1
              }}
              place='bottom'
              clickable={true}
              isOpen={open}
              setIsOpen={setOpen}
              openOnClick={true}
              // isOpen={open}
              render={({ content, activeAnchor }) => {
                return (
                  <div>
                    <GetContainer
                      url={`/student/teachers/${teacherId}/daily/slot/?date=${moment(day).format('YYYY-MM-DD')}`}
                      params={{ random }}
                    >
                      {({ data: slots }) => (
                        <div className='teachers-schedule'>
                          <div className='teachers-schedule__day'>{moment(day)?.format('ddd, MMM D, YYYY')}</div>
                          <div className='teachers-schedule__desc'>{t('Select a time slot to book a class')}</div>
                          <ul className='teachers-schedule__list'>
                            {slots?.data?.map(slot => (
                              <li
                                className={slot?.schedule_id ? 'unavailable' : 'available'}
                                key={slot?.id + `${slot?.date}T${slot?.time}`}
                              >
                                <span>{slot?.time}</span>
                                <span>
                                  {slot?.schedule_id
                                    ? t('unavailable')
                                    : isAfterDate(
                                        moment(`${slot?.date} ${slot?.time}`, 'YYYY-MM-DD HH:mm:ss')
                                          .subtract(24, 'hours')
                                          .toDate()
                                      )
                                    ? t('available')
                                    : t('unavailable')}
                                </span>
                                {!slot?.schedule_id &&
                                  isAfterDate(
                                    moment(`${slot?.date} ${slot?.time}`, 'YYYY-MM-DD HH:mm:ss')
                                      .subtract(24, 'hours')
                                      .toDate()
                                  ) && (
                                    <button className='btn btn-transparent' onClick={() => setSlot(slot)}>
                                      {t('Book Class')}
                                    </button>
                                  )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </GetContainer>
                  </div>
                )
              }}
            />
          )}
          <RequestModal
            isOpen={!!slot}
            close={() => setSlot(null)}
            title='Request individual title'
            error={error}
            reverseClasses
            isSuccess={isSuccess}
            description={
              <>
                <p>{subject?.name?.[i18n.language]}</p>
                <p>
                  {moment(day)?.format('ddd, MMM D, YYYY')} {slot?.time}
                </p>
              </>
            }
            send={() =>
              mutate(
                {
                  subject_id: subject?.id,
                  slot_id: slot?.id
                },
                {
                  onSuccess: () => {
                    setRandom(Math.random())
                    refresh()
                  }
                }
              )
            }
            acceptBtnText='Yes, I will'
            cancelBtnText='No'
            successTitle='Request individual success title'
            successDesc='Request individual success desc'
            errorTitle='Request individual success title'
          />
        </Fragment>
      )}
    </GetContainer>
  )
}

export default TeacherCalendar
