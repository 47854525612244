import React, { useContext, useState } from 'react'
import { ISlot } from '../../../../../types/types'
import { useCustomMutation } from '../../../../../hooks/useCustomMutation'
import { RandomContext } from '../../../../../providers/RandomProvider'
import RequestModal from '../../../../../components/request-modal'
import Timer from '../timer/Timer'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../../../../configs/request'
import { isAfterDate, isWithin24Hours } from '../../../../../configs/helpers'
import moment from 'moment/moment'

interface IProps {
  data: ISlot
}

function LessonCard(props: IProps) {
  const { t, i18n } = useTranslation()

  const { mutate: enter, error, isSuccess } = useCustomMutation('/student/slots/enter', 'POST')
  const {
    mutate: cancel,
    error: cancelError,
    isSuccess: successCancel
  } = useCustomMutation('/student/slots/cancel', 'POST')
  const { setRandom } = useContext(RandomContext)
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  return (
    <div className='history-table__row'>
      <Timer time={props.data?.time} />

      <div className='history-table__date'>
        {/*{props.data?.time} {props.data?.date}*/}
        {`${props.data?.time} ${moment(props.data?.date).format('DD.MM.YYYY')}`}
        {/* <span>11:30-11:55+05</span> */}
      </div>
      <div className='course-type'>
        <span className='course-type__ico'>
          <img src={MEDIA_URL + props.data?.schedule?.group?.subject?.icon} alt='icon' />
        </span>
        <span>
          {props.data?.schedule?.lesson?.name?.[i18n.language]}
          {/* <p>{props.data?.schedule?.group?.lesson?.title?.[i18n.language]}</p> */}
        </span>
      </div>
      <div className='history-table__type'>{t(props.data?.schedule?.group?.type)}</div>
      <div className='history-table__teacher'>
        <span>
          {props.data?.schedule?.replacement_teacher
            ? `${props.data?.schedule?.replacement_teacher?.firstname} ${props.data?.schedule?.replacement_teacher?.lastname}`
            : `${props.data?.schedule?.group?.teacher?.firstname} ${props.data?.schedule?.group?.teacher?.lastname}`}
        </span>
      </div>
      <div className='history-table__btns'>
        {props.data?.schedule?.group?.type === 'individual' &&
          isAfterDate(`${props?.data?.date}T${props.data?.time}`) && (
            <button
              className='btn btn-transparent'
              onClick={() => {
                setOpen(true)
                setName('cancel')
              }}
            >
              {t('Cancel')}
            </button>
          )}
        <button
          className='btn'
          onClick={() => {
            setOpen(true)
            setName('enter')
          }}
        >
          {t('Go to class')}
        </button>
      </div>
      <RequestModal
        isOpen={open}
        close={() => setOpen(false)}
        title={`Request ${name} title`}
        error={error || cancelError}
        isSuccess={isSuccess || successCancel}
        description={
          name === 'cancel' && isWithin24Hours(`${props.data?.date} ${props.data?.time}`) ? (
            <span className='danger-text'>
              {t('Less than 24 hours left until the lesson starts, coins will not be refunded!')}
            </span>
          ) : (
            <div />
          )
        }
        send={() => {
          if (name === 'enter') {
            enter(
              { slot_id: props.data.id },
              {
                onSuccess(data, variables, context) {
                  if (data?.data?.zoom_link) {
                    window.open(data?.data?.zoom_link, '_blank')
                  }
                  setRandom(Math.random())
                }
              }
            )
          } else {
            cancel(
              {
                schedule_id: props.data?.schedule_id
              },
              {
                onSuccess(data, variables, context) {
                  setRandom(Math.random())
                },
                onError: () => {}
              }
            )
          }
        }}
        acceptBtnText='Yes, I will'
        cancelBtnText='No'
        successTitle={`Request ${name} success title`}
        successDesc={`Request ${name} success desc`}
        errorTitle={`Request ${name} error title`}
      />
    </div>
  )
}

export default LessonCard
