import GetContainer from '../../../components/get-container'
import { useNavigate, useParams } from 'react-router-dom'
import { ISlot } from '../../../types/types'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, useState } from 'react'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import RequestModal from '../../../components/request-modal'
import WithHTML from '../../../components/with-html'

export const HomeWork = () => {
  const { id } = useParams()
  const { t, i18n } = useTranslation()
  const [file, setFile] = useState()
  const { mutate } = useCustomMutation('/media_upload', 'POST')
  const { mutate: submit, error, isSuccess } = useCustomMutation('/student/homeworks', 'POST')
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const selectFile = (e: ChangeEvent<HTMLInputElement>) => {
    let data = new FormData()
    if (e.currentTarget.files?.[0]) {
      data?.append('directory', 'homework')
      data?.append('file', e.currentTarget.files?.[0])
      mutate(data, {
        onSuccess(data, variables, context) {
          setFile(data?.data)
        }
      })
    }
  }

  return (
    <GetContainer url={'/student/slots/' + id}>
      {({ data }: { data: { data: ISlot } }) => (
        <div className='homework-main'>
          <div className='homework-head'>
            <div className='homework__title'>{data?.data?.schedule?.lesson?.name?.[i18n.language]}</div>
            <a href='#' className='homework__link'>
              {t('View Lesson')}
            </a>
          </div>
          <ul className='homework-info'>
            <li>
              {t('Grade')}: <span>{data?.data?.schedule?.lesson?.unit?.grade?.name?.[i18n.language]}</span>
            </li>
            <li>
              {t('Unit')}: <span>{data?.data?.schedule?.lesson?.unit?.name?.[i18n.language]}</span>
            </li>
            <li>
              {t('Subject')}: <span>{data?.data?.schedule?.lesson?.unit?.grade?.subject?.name?.[i18n.language]}</span>
            </li>
          </ul>
          <div className='homework-content'>
            <div className='homework-content__title'>
              {data?.data?.schedule?.lesson?.homework?.name?.[i18n.language]}
            </div>
            <div className='homework-content__text'>
              <WithHTML html={data?.data?.schedule?.lesson?.homework?.description?.[i18n.language]} />
            </div>

            {/* <a href='#' className='homework-content__download'>
              <span>Download Homework</span>
              <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M9 11V17L11 15M9 17L7 15'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22 10H18C15 10 14 9 14 6V2L22 10Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </a> */}
          </div>
          {!data?.data?.schedule?.student_homework && (
            <div className='homework-upload'>
              <div>{file || t('Select file')}</div>
              <label htmlFor='file'>
                <span>{t('Browse')}</span>
                <input
                  type='file'
                  id='file'
                  accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*'
                  onChange={selectFile}
                />
              </label>
              <button
                className='btn btn-trans'
                onClick={() =>
                  submit(
                    { schedule_id: data?.data?.schedule_id, file: file },
                    {
                      onError: () => {
                        setOpen(true)
                      },
                      onSuccess: () => setOpen(true)
                    }
                  )
                }
              >
                {t('Submit Homework')}
              </button>
            </div>
          )}
          <RequestModal
            isOpen={open}
            close={() => {
              setOpen(false)
              navigate(-1)
            }}
            title='Request subjects title'
            error={error}
            isSuccess={isSuccess}
            description={
              <>
                {/* <p>
                    {data?.data
                      ?.filter((subject: ISubject) =>
                        form.watch('subject_ids')?.some((id: number) => id === subject?.id)
                      )
                      ?.map((subject: ISubject) => subject?.name?.[i18n.language])
                      .join(', ')}
                  </p> */}
              </>
            }
            send={() => {}}
            acceptBtnText=''
            cancelBtnText=''
            successTitle='Request homework complete success title'
            successDesc='Request homework complete success desc'
            errorTitle='Request homework complete error title'
          />
        </div>
      )}
    </GetContainer>
  )
}
