import { useContext } from 'react'
import GetContainer from '../../../components/get-container'
import { INotification } from '../../../types/types'
import NotificationCard from './components/NotificationCard'
import { RandomContext } from '../../../providers/RandomProvider'

export const Notifications = () => {
  const { random } = useContext(RandomContext)
  return (
    <div className='messages-wrap'>
      <GetContainer url='student/notifications' params={{ random }}>
        {({ data }) => (
          <ul className='messages-nots'>
            {data?.data?.map((item: INotification) => (
              <NotificationCard item={item} key={item?.id} />
            ))}
          </ul>
        )}
      </GetContainer>
    </div>
  )
}
