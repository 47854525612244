import { error } from 'console'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'

const customStyles = {
  overlay: {
    zIndex: 10000,
    border: 'none'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 1,
    backgroundColor: 'white',
    boxShadow: `0px 15px 50px 0px rgba(0, 0, 0, 0.08)`,
    borderRadius: '1rem'
  }
}

interface IProps {
  isOpen: boolean
  close: () => void
  isSuccess?: boolean
  error?: any
  title: string
  description: ReactNode
  successTitle: string
  successDesc: string
  errorTitle: string
  send: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  acceptBtnText: string
  cancelBtnText: string
  reverseClasses?: boolean
}

function RequestModal(props: IProps) {
  const { t } = useTranslation()
  return (
    // @ts-ignore
    <ReactModal style={customStyles} isOpen={props.isOpen} className="individual-mask" onRequestClose={props.close}>
      <div className='individual-popup' style={{ display: 'block', width: 'auto' }}>
        <div className='individual-popup__content'>
          {props.isSuccess ? (
            <div className='individual-popup__done'>
              <div className='individual-popup__title'>{t(props.successTitle)}</div>
              <div className='individual-popup__img'>
                <svg width='150' height='112' viewBox='0 0 150 112' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <rect
                    y='111.512'
                    width='111.024'
                    height='150'
                    rx='55.5118'
                    transform='rotate(-90 0 111.512)'
                    fill='#08B84E'
                    fill-opacity='0.1'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M53.1523 57.7154L66.4184 70.7658L66.3327 70.6815L96.2626 41.2383'
                    fill='#08B84E'
                    fill-opacity='0.1'
                  />
                  <path
                    d='M53.1523 57.7154L66.4184 70.7658L66.3327 70.6815L96.2626 41.2383'
                    stroke='#1F4F51'
                    stroke-width='15'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <div className='individual-popup__text'>
                <p>{t(props.successDesc)}</p>
              </div>
            </div>
          ) : props.error ? (
            <div className='individual-popup__done'>
              <div className='individual-popup__title'>{t(props.errorTitle)}</div>
              <div className='individual-popup__img'>
                <svg width='150' height='112' viewBox='0 0 150 112' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <rect
                    y='111.512'
                    width='111.024'
                    height='150'
                    rx='55.5118'
                    transform='rotate(-90 0 111.512)'
                    fill='#FF1477'
                    fill-opacity='0.1'
                  />
                  <path
                    d='M80.47 56.4983L94.386 42.5823C95.0464 41.923 95.4179 41.0283 95.4188 40.0951C95.4196 39.1619 95.0497 38.2666 94.3904 37.6062C93.7311 36.9457 92.8364 36.5742 91.9032 36.5734C90.9701 36.5726 90.0748 36.9425 89.4143 37.6018L75.4983 51.5178L61.5823 37.6018C60.9218 36.9414 60.0261 36.5703 59.092 36.5703C58.158 36.5703 57.2623 36.9414 56.6018 37.6018C55.9414 38.2623 55.5703 39.158 55.5703 40.092C55.5703 41.0261 55.9414 41.9218 56.6018 42.5823L70.5178 56.4983L56.6018 70.4143C55.9414 71.0748 55.5703 71.9705 55.5703 72.9045C55.5703 73.8386 55.9414 74.7343 56.6018 75.3948C57.2623 76.0552 58.158 76.4263 59.092 76.4263C60.0261 76.4263 60.9218 76.0552 61.5823 75.3948L75.4983 61.4788L89.4143 75.3948C90.0748 76.0552 90.9705 76.4263 91.9045 76.4263C92.8386 76.4263 93.7343 76.0552 94.3948 75.3948C95.0552 74.7343 95.4263 73.8386 95.4263 72.9045C95.4263 71.9705 95.0552 71.0748 94.3948 70.4143L80.47 56.4983Z'
                    fill='#FF1477'
                  />
                </svg>
              </div>
              <div className='individual-popup__text'>
                <p>{props.error?.response?.data?.message}</p>
              </div>
            </div>
          ) : (
            <>
              <div className='individual-popup__title'>{t(props.title)}</div>
              <div className='individual-popup__text'>
                {/* <p>{`${props?.item?.teacher?.firstname} ${props?.item?.teacher?.lastname}`}</p>
                <p>Nov 8(Tue) 09:30-09:55</p> */}
                {props.description}
              </div>
              <div className='individual-popup__btns'>
                <button onClick={props.close} className={`btn btn-gray`}>
                  {t(props.cancelBtnText)}
                </button>
                <button onClick={e => props.send(e)} className={`btn `}>
                  {t(props.acceptBtnText)}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </ReactModal>
  )
}

export default RequestModal
