import React from 'react'
import Form from '../../../../components/form'
import * as Yup from 'yup'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CalendarFill from '../../../../assets/img/icons/CalendarFill'
import { Tooltip } from 'react-tooltip'
import Calendar from 'react-calendar'
import GetContainer from '../../../../components/get-container'
import { ISubject } from '../../../../types/types'
import moment from 'moment'
import { uzLocale } from '../../../teachers-pages/teacher-home/component/monthly-slot'

export const DAYS = [
  { name: 'Monday', short: 'Mon', id: 1 },
  { name: 'Tuesday', short: 'Tues', id: 2 },
  { name: 'Wednesday', short: 'Wed', id: 3 },
  { name: 'Thursday', short: 'Thurs', id: 4 },
  { name: 'Friday', short: 'Fri', id: 5 },
  { name: 'Saturday', short: 'Sat', id: 6 },
  { name: 'Sunday', short: 'Sun', id: 0 }
]

const validationSchema = Yup.object().shape({
  subject_ids: Yup.array(Yup.number().required('This field is required')),
  start_date: Yup.string().required('This field is required'),
  end_date: Yup.string().required('This field is required'),
  start_time: Yup.string().required('This field is required'),
  end_time: Yup.string().required('This field is required'),
  day_of_week: Yup.array(Yup.number().required('This field is required')),
  gender: Yup.string().required('This field is required')
})

interface IProps {
  value: {
    subject_ids: never[]
    start_date: string
    end_date: string
    start_time: string
    end_time: string
    day_of_week: number[]
    gender: string
    search: string
  }
  setValue: React.Dispatch<
    React.SetStateAction<{
      subject_ids: never[]
      start_date: string
      end_date: string
      start_time: string
      end_time: string
      day_of_week: number[]
      gender: string
      search: string
    }>
  >
  selfSubjects: ISubject[]
}

function LessonsFilter(props: IProps) {
  const { t, i18n } = useTranslation()
  return (
    <Form url='' initialValues={props.value} validationSchema={validationSchema} method=''>
      {({ form, handleFinish }) => {
        const { control, handleSubmit, watch, setValue } = form
        return (
          <div className='filter'>
            <div className='filter__head'>
              <div className='filter__wrap'>
                <div className='filter__title'>{t('Who are you looking for?')}</div>
                <div className='filter-search'>
                  <Controller
                    name={'search'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <div className='search-input'>
                        <input
                          type='text'
                          value={value}
                          onChange={e => onChange(e.target?.value)}
                          className='search-input__input'
                          placeholder={t('Search') + ' ...'}
                        />
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          className='search-input__ico'
                        >
                          <path
                            d='M18.3327 18.3337L16.666 16.667M9.58268 17.5003C10.6223 17.5003 11.6518 17.2956 12.6123 16.8977C13.5728 16.4999 14.4455 15.9167 15.1806 15.1816C15.9157 14.4465 16.4989 13.5737 16.8967 12.6132C17.2946 11.6527 17.4993 10.6233 17.4993 9.58366C17.4993 8.54403 17.2946 7.51458 16.8967 6.55408C16.4989 5.59359 15.9157 4.72086 15.1806 3.98573C14.4455 3.2506 13.5728 2.66746 12.6123 2.26961C11.6518 1.87176 10.6223 1.66699 9.58268 1.66699C7.48305 1.66699 5.46942 2.50107 3.98475 3.98573C2.50009 5.47039 1.66602 7.48403 1.66602 9.58366C1.66602 11.6833 2.50009 13.6969 3.98475 15.1816C5.46942 16.6662 7.48305 17.5003 9.58268 17.5003V17.5003Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    )}
                  />
                </div>
              </div>
              <Controller
                name={'gender'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className='filter-gender'>
                    <label htmlFor='all'>
                      <input
                        type='radio'
                        name='gender'
                        checked={value === 'all'}
                        onChange={e => onChange(e?.target?.checked ? 'all' : 'male')}
                        id='all'
                      />
                      <span className='btn btn-white'>{t('All')}</span>
                    </label>
                    <label htmlFor='male'>
                      <input
                        type='radio'
                        name='gender'
                        checked={value === 'male'}
                        onChange={e => onChange(e?.target?.checked ? 'male' : 'female')}
                        id='male'
                      />
                      <span className='btn btn-white'>{t('Male')}</span>
                    </label>
                    <label htmlFor='female'>
                      <input
                        type='radio'
                        checked={value === 'female'}
                        onChange={e => onChange(e?.target?.checked ? 'female' : 'male')}
                        name='gender'
                        id='female'
                      />
                      <span className='btn btn-white'>{t('Female')}</span>
                    </label>
                  </div>
                )}
              />
            </div>
            <div className='filter__main'>
              <div className='filter-date'>
                <div className='filter-title'>{t('Day')}</div>
                <div className='filter-wrap'>
                  <Controller
                    name={'start_date'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <div className='calendar-input' style={{ backgroundColor: '#fff', borderRadius: 10 }}>
                        <input
                          type='date'
                          placeholder='...gacha'
                          value={moment(value).format('YYYY-MM-DD')}
                          onChange={e => onChange(e.target.value)}
                          style={{ minWidth: '90%', fontSize: '0.7rem' }}
                        />
                        <a data-tooltip-id='calendar' data-some-relevant-attr={'start_date'}>
                          <CalendarFill />
                        </a>
                      </div>
                    )}
                  />
                  <Controller
                    name={'end_date'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <div className='calendar-input' style={{ backgroundColor: '#fff', borderRadius: 10 }}>
                        <input
                          type='date'
                          placeholder='...gacha'
                          style={{ minWidth: '90%', fontSize: '0.7rem' }}
                          value={moment(value).format('YYYY-MM-DD')}
                          onChange={e => onChange(e.target.value)}
                        />
                        <a data-tooltip-id='calendar' data-some-relevant-attr={'end_date'}>
                          <CalendarFill />
                        </a>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className='filter-time'>
                <div className='filter-title'>{t('Time')}</div>
                <GetContainer url='student/slots/time'>
                  {({ data }) => (
                    <div className='filter-wrap'>
                      <Controller
                        control={control}
                        name='start_time'
                        render={({ field: { onChange, value } }) => (
                          <select value={value} onChange={e => onChange(e.target.value)}>
                            {data?.map((time: string) => (
                              <option value={time} key={time + 'start'}>
                                {time}
                              </option>
                            ))}
                            {/* Add more options here */}
                          </select>
                        )}
                      />
                      <Controller
                        control={control}
                        name='end_time'
                        render={({ field: { onChange, value } }) => (
                          <select value={value} onChange={e => onChange(e.target.value)}>
                            {data?.map((time: string) => (
                              <option value={time} key={time + 'start'}>
                                {time}
                              </option>
                            ))}
                            {/* Add more options here */}
                          </select>
                        )}
                      />
                    </div>
                  )}
                </GetContainer>
              </div>
              <div className='filter-days'>
                <div className='filter-title'>{t('Day of the week')}</div>
                <Controller
                  control={control}
                  name='day_of_week'
                  render={({ field: { onChange, value } }) => (
                    <div className='filter-wrap'>
                      {DAYS?.map(day => (
                        <label htmlFor={`${day?.id}`} key={day?.id}>
                          <input
                            type='checkbox'
                            name='day_of_week'
                            checked={value?.some((el: number) => el === day?.id)}
                            onChange={e =>
                              onChange(
                                e.target?.checked ? [...value, day?.id] : value?.filter((el: number) => el !== day?.id)
                              )
                            }
                            id={`${day?.id}`}
                          />
                          <span style={{ fontSize: '0.5rem' }}>{t(day?.short)}</span>
                        </label>
                      ))}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className='filter-courses'>
              <div className='filter-title'>{t('Courses')}</div>

              <GetContainer url='/student/subjects' hideLoading params={{ type: 'individual' }}>
                {({ data }) => {
                  return (
                    <Controller
                      control={control}
                      name='subject_ids'
                      render={({ field: { onChange, value } }) => (
                        <div className='filter-wrap'>
                          {data?.data?.map((subject: ISubject) => (
                            <label
                              htmlFor={subject?.slug}
                              key={subject?.slug + value?.some((item: ISubject) => item?.id === subject?.id)}
                            >
                              <input
                                type='checkbox'
                                checked={value?.some((item: number) => item === subject?.id)}
                                onChange={e =>
                                  onChange(
                                    e?.target?.checked
                                      ? [...value, subject?.id]
                                      : value?.filter((item: number) => item !== subject?.id)
                                  )
                                }
                                disabled={!props?.selfSubjects?.some((item: ISubject) => item?.id === subject?.id)}
                                name='subject_ids'
                                id={subject?.slug}
                              />
                              <span>{subject?.name?.[i18n?.language]}</span>
                            </label>
                          ))}
                        </div>
                      )}
                    />
                  )
                }}
              </GetContainer>
            </div>
            <Controller
              control={control}
              name='start_date'
              render={({ field: { onChange: start_date_change, value: start_date, ref } }) => (
                <Controller
                  control={control}
                  name='end_date'
                  render={({ field: { onChange: end_date_change, value: end_date, ref } }) => (
                    <Tooltip
                      id='calendar'
                      place='bottom'
                      offset={20}
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
                        zIndex: 9999
                      }}
                      clickable={true}
                      openOnClick={true}
                      render={({ content, activeAnchor }) => {
                        return (
                          <Calendar
                            onChange={
                              (activeAnchor?.getAttribute('data-some-relevant-attr') as string) === 'start_date'
                                ? start_date_change
                                : end_date_change
                            }
                            value={
                              (activeAnchor?.getAttribute('data-some-relevant-attr') as string) === 'start_date'
                                ? new Date(start_date)
                                : new Date(end_date)
                            }
                            minDate={
                              (activeAnchor?.getAttribute('data-some-relevant-attr') as string) === 'start_date'
                                ? new Date()
                                : new Date(start_date)
                            }
                            locale={i18n.language}
                            // Oyning nomini chiqarish
                            formatMonthYear={
                              i18n?.language === 'uz'
                                ? (locale, date) => `${uzLocale.months[date.getMonth()]} ${date.getFullYear()}`
                                : undefined
                            }
                            formatMonth={
                              i18n?.language === 'uz'
                                ? (locale, date) => `${uzLocale.months[date.getMonth()]}`
                                : undefined
                            }
                            // Hafta kunlarini qisqa shaklda chiqarish (Ya, Du, Se...)
                            formatShortWeekday={
                              i18n?.language === 'uz'
                                ? (locale, date) => uzLocale.weekdaysShort[date.getDay()]
                                : undefined
                            }
                            // Hafta kunlarini to'liq yozish (Yakshanba, Dushanba...)
                            formatWeekday={
                              i18n?.language === 'uz'
                                ? (locale, date) => uzLocale.weekdaysLong[date.getDay()]
                                : undefined
                            }
                          />
                        )
                      }}
                    />
                  )}
                />
              )}
            />
            <button className='btn btn-orange' onClick={handleSubmit((data: any) => props.setValue(data))}>
              {t('Search')}
            </button>
          </div>
        )
      }}
    </Form>
  )
}

export default LessonsFilter
