import React from 'react'
import Crown from '../../../../assets/img/mock/Crown'
import Clock from '../../../../assets/img/icons/Clock'
import Coin from '../../../../assets/img/icons/Coin'
import { ISubject } from '../../../../types/types'
import { MEDIA_URL } from '../../../../configs/request'
import { useTranslation } from 'react-i18next'

interface IProps {
  item: ISubject
}

function CourseCard({ item }: IProps) {
  const { t, i18n } = useTranslation()
  return (
    <div className='course-item'>
      <div className='course__head'>
        <img src={MEDIA_URL + item.icon} alt={item.slug} />
      </div>
      <div className='course__name'>{item?.name?.[i18n.language]}</div>

      <div className='course-info'>
        <div className='course-info__item'>
          <span>{t('Ages')}:</span>
          <span>{item.level}</span>
        </div>
      </div>
      <div className='course__desc'>{item.title?.[i18n.language]}</div>
      <div className='course-numbers'>
        <div className='course-numbers__item'>
          <div className='course-numbers__ico'>
            <Coin width='2rem' height='2rem'/>
          </div>
          <div className='course-numbers__name'>{t('Price for per course')}</div>
          <div className='course-numbers__value'>
            {item?.options?.map(item => item.price)?.join(', ')} {t('Coins')}
          </div>
        </div>
        <div className='course-numbers__item'>
          <div className='course-numbers__ico'>
            <Clock width='2rem' height='2rem'/>
          </div>
          <div className='course-numbers__name'>{t('Duration of per course')}</div>
          <div className='course-numbers__value'>
            {item?.options?.map(item => item.duration)?.join(', ')} {t('min')}
          </div>
        </div>
      </div>
      <a href={'/student/school/courses/' + item.slug} className='course__btn btn btn-lightblue'>
        {t('More')}
      </a>
    </div>
  )
}

export default CourseCard
