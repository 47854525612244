import { useQuery } from '@tanstack/react-query'
import { ReactNode, useContext, useEffect, useState } from 'react'
import React from 'react'
import { request } from '../../configs/request'
import { AuthContext } from '../../context/AuthContext'
import moment from 'moment'
interface IProps {
  url: string
  params?: object
  children: (props: any) => ReactNode
  onSuccess?: (data: any) => void
  onError?: (data: any) => void
  hideLoading?: boolean
}

function GetContainer(props: IProps) {
  const { logout } = useContext(AuthContext)
  const [time, setTime] = useState<Date | null>(null)
  const { data, isLoading, error, isError, refetch, isSuccess, isFetched, failureCount } = useQuery({
    queryKey: [props.url, ...(props?.params ? Object.values(props.params) : [])],
    queryFn: async () => {
      const response: any = await request({
        url: props.url,
        params: props.params,
        method: 'GET'
      })
      setTime(new Date())
      return response.data
    },
    enabled: !!props.url
  })

  console.log(isSuccess, isFetched)

  useEffect(() => {
    if (isSuccess && data && props.onSuccess) {
      props.onSuccess(data)
    }
  }, [data, isSuccess, moment(time).format('DD-MM-YYYY HH:mm:ss')])

  return (
    <>
      {props.children({
        data: data,
        isLoading,
        error,
        isError,
        refetch
      })}
    </>
  )
}

export default GetContainer
