import React, { useState } from 'react'
import { ITeacher } from '../../../../types/types'
import { MEDIA_URL } from '../../../../configs/request'
import { useTranslation } from 'react-i18next'
import Play from '../../../../assets/img/icons/Play'
import VideoModal from '../../../../components/modals/VideoModal'

interface IProps {
  item: ITeacher
}

function TeacherInfo(props: IProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  return (
    <div className='teachers-info'>
      <div className='teachers-item__img'>
        <img
          src={props.item?.photo ? MEDIA_URL + props.item?.photo : require('../../../../assets/img/mock/teacher.png')}
          alt='img'
        />
        {!!props.item?.info?.video && (
          <button
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              border: 'none',
              backgroundColor: 'transparent'
            }}
            onClick={() => setOpen(true)}
          >
            <Play />
          </button>
        )}
      </div>
      <div className='teachers-info__wrap'>
        <div className='teachers-item__name'>{`${props.item?.firstname} ${props.item?.lastname}`}</div>
        <div className='teachers-item__pos'>{t(props.item?.role)}</div>
        <div className='teachers-info__title'>{t('Bio')}</div>
        <div className='teachers-info__text'>{props.item?.info?.bio}</div>
        {/* <div className='teachers-info__title'>Message</div>
        <div className='teachers-info__text'>
          Hello, I look forward to meeting you! I promise to keep our classes together fun, I even have some pokemon
          buddies. Take care and hopefully, we will meet soon!
        </div> */}
      </div>
      <VideoModal open={open} onClose={() => setOpen(false)} url={props.item?.info?.video} />
    </div>
  )
}

export default TeacherInfo
