import React from 'react'
import './SuccessModal.scss'
import Modal from 'react-modal'
import { MEDIA_URL } from '../../configs/request'

const customStyles = {
  overlay: { zIndex: 1000, border: 'none' },
  content: {
    backgroundColor: 'transparent'
  }
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

interface VideoModalProps {
  open: boolean
  url: string
  onClose: () => void
}

const VideoModal: React.FC<VideoModalProps> = ({ url, onClose, open }) => {
  return (
    // @ts-ignore
    <Modal
      isOpen={open}
      //   onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel='Example Modal'
      className="modal-video"
    >
      {/* <div className='modal-overlay' style={{ display: open ? 'block' : 'none' }}> */}
      <video controls>
        <source src={MEDIA_URL + url} type='video/mp4' />
        {/* <source src="movie.ogg" type="video/ogg"/> */}
        Your browser does not support the video tag.
      </video>

      {/* </div> */}
    </Modal>
  )
}

export default VideoModal
