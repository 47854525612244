import React, { useContext } from 'react'
import CalendarFill from '../../../../../assets/img/icons/CalendarFill'
import Timer from '../../../../../assets/img/icons/Timer'
import Calendar from 'react-calendar'
import { Tooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import TimePicker from './TimePicker'
import Form from '../../../../../components/form'
import * as Yup from 'yup'
import { Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import moment from 'moment'
import { RandomContext } from '../../../../../providers/RandomProvider'

const validationSchema = Yup.object().shape({
  start_date: Yup.string().required('This field is required!'),
  end_date: Yup.string().required('This field is required!'),
  start_time: Yup.string().required('This field is required!'),
  end_time: Yup.string().required('This field is required!')
})

export const uzLocale = {
  months: [
    'Yanvar',
    'Fevral',
    'Mart',
    'Aprel',
    'May',
    'Iyun',
    'Iyul',
    'Avgust',
    'Sentabr',
    'Oktabr',
    'Noyabr',
    'Dekabr'
  ],
  weekdaysLong: ['Yakshanba', 'Dushanba', 'Seshanba', 'Chorshanba', 'Payshanba', 'Juma', 'Shanba'],
  weekdaysShort: ['Ya', 'Du', 'Se', 'Ch', 'Pa', 'Ju', 'Sh']
}

function MonthlySlot() {
  const { t, i18n } = useTranslation()
  const { setRandom }: any = useContext(RandomContext)
  return (
    <Form
      url='teacher/slots/monthly'
      validationSchema={validationSchema}
      onSuccess={() => {
        toast.success(t('Success!'))
        setRandom(Math.random())
      }}
      method='POST'
      initialValues={{
        start_date: new Date(), //required
        end_date: new Date(), //required
        start_time: '06:00', //required
        end_time: '06:00' //required
      }}
    >
      {({ form, createInfo, handleFinish }) => {
        const {
          control,
          formState: { errors },
          handleSubmit,
          watch
        } = form
        return (
          <div className='calendar-search'>
            <Controller
              control={control}
              name='start_date'
              render={({ field: { onChange, value } }) => (
                <div className='calendar-input' style={{ backgroundColor: '#fff', borderRadius: 10 }}>
                  <input
                    type='date'
                    placeholder='...gacha'
                    value={moment(value).format('YYYY-MM-DD')}
                    onChange={e => onChange(e.target.value)}
                  />
                  <a data-tooltip-id='calendar' data-some-relevant-attr={'start_date'}>
                    <CalendarFill />
                  </a>
                </div>
              )}
            />
            <Controller
              control={control}
              name='end_date'
              render={({ field: { onChange, value } }) => (
                <div className='calendar-input' style={{ backgroundColor: '#fff', borderRadius: 10 }}>
                  <input
                    type='date'
                    placeholder='...gacha'
                    value={moment(value).format('YYYY-MM-DD')}
                    onChange={e => onChange(e.target.value)}
                  />
                  <a data-tooltip-id='calendar' data-some-relevant-attr={'end_date'}>
                    <CalendarFill />
                  </a>
                </div>
              )}
            />
            <a data-tooltip-id='time'>
              <div className='calendar-input' style={{ backgroundColor: '#fff', borderRadius: 10 }}>
                <input
                  type='text'
                  value={
                    watch('start_time') && watch('end_time') ? `${watch('start_time')} - ${watch('end_time')}` : ''
                  }
                  disabled
                  style={{ backgroundColor: '#fff' }}
                  placeholder='Vaqtni tanlash'
                />
                <Timer />
              </div>
            </a>

            <button
              className='calendar-btn btn'
              onClick={handleSubmit(data =>
                handleFinish({
                  ...data,
                  start_date: moment(data?.start_date).format('YYYY-MM-DD'),
                  end_date: moment(data?.end_date).format('YYYY-MM-DD')
                })
              )}
            >
              {t('Add to table')}
            </button>
            <Controller
              control={control}
              name='start_date'
              render={({ field: { onChange: start_date_change, value: start_date, ref } }) => (
                <Controller
                  control={control}
                  name='end_date'
                  render={({ field: { onChange: end_date_change, value: end_date, ref } }) => (
                    <Tooltip
                      id='calendar'
                      place='bottom'
                      offset={20}
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
                        zIndex: 9999
                      }}
                      clickable={true}
                      openOnClick={true}
                      render={({ content, activeAnchor }) => {
                        return (
                          <Calendar
                            locale={i18n.language}
                            onChange={
                              (activeAnchor?.getAttribute('data-some-relevant-attr') as string) === 'start_date'
                                ? start_date_change
                                : end_date_change
                            }
                            value={
                              (activeAnchor?.getAttribute('data-some-relevant-attr') as string) === 'start_date'
                                ? start_date
                                : end_date
                            }
                            minDate={
                              (activeAnchor?.getAttribute('data-some-relevant-attr') as string) === 'start_date'
                                ? new Date()
                                : start_date
                            }
                            // Oyning nomini chiqarish
                            formatMonthYear={
                              i18n?.language === 'uz'
                                ? (locale, date) => `${uzLocale.months[date.getMonth()]} ${date.getFullYear()}`
                                : undefined
                            }
                            formatMonth={
                              i18n?.language === 'uz'
                                ? (locale, date) => `${uzLocale.months[date.getMonth()]}`
                                : undefined
                            }
                            // Hafta kunlarini qisqa shaklda chiqarish (Ya, Du, Se...)
                            formatShortWeekday={
                              i18n?.language === 'uz'
                                ? (locale, date) => uzLocale.weekdaysShort[date.getDay()]
                                : undefined
                            }
                            // Hafta kunlarini to'liq yozish (Yakshanba, Dushanba...)
                            formatWeekday={
                              i18n?.language === 'uz'
                                ? (locale, date) => uzLocale.weekdaysLong[date.getDay()]
                                : undefined
                            }
                          />
                        )
                      }}
                    />
                  )}
                />
              )}
            />
            <Tooltip
              id='time'
              place='bottom'
              offset={20}
              style={{
                backgroundColor: 'white',
                color: 'black',
                boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
                zIndex: 9999
              }}
              clickable={true}
              openOnClick={true}
              render={({ content, activeAnchor }) => {
                return <TimePicker control={control} />
              }}
            />
          </div>
        )
      }}
    </Form>
  )
}

export default MonthlySlot
