import { useTranslation } from 'react-i18next'
import GetContainer from '../../../components/get-container'
import { ICoursePart } from '../../../types/types'
import GroupCourseCard from './components/GroupCourseCard'
import CalendarFill from '../../../assets/img/icons/CalendarFill'
import { useState } from 'react'
import Calendar from 'react-calendar'
import { Tooltip } from 'react-tooltip'
import moment from 'moment'
import { start } from 'repl'
import { uzLocale } from '../../teachers-pages/teacher-home/component/monthly-slot'

export const GroupCourses = () => {
  const { t, i18n } = useTranslation()
  const [search, setSearch] = useState('')
  const [date, setDate] = useState(new Date())
  return (
    <div className='courses-group'>
      <div className='courses-group__filter'>
        <div className='courses-group__wrap'>
          <div className='courses-group__title'>{t('View group lessons')}</div>
          <div className='search'>
            <div className='search-input'>
              <input
                type='text'
                className='search-input__input'
                value={search}
                onChange={e => setSearch(e?.target?.value)}
                placeholder={`${t('Search for lessons')} ...`}
              />

              <svg
                width={20}
                height={20}
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='search-input__ico'
              >
                <path
                  d='M18.3327 18.3337L16.666 16.667M9.58268 17.5003C10.6223 17.5003 11.6518 17.2956 12.6123 16.8977C13.5728 16.4999 14.4455 15.9167 15.1806 15.1816C15.9157 14.4465 16.4989 13.5737 16.8967 12.6132C17.2946 11.6527 17.4993 10.6233 17.4993 9.58366C17.4993 8.54403 17.2946 7.51458 16.8967 6.55408C16.4989 5.59359 15.9157 4.72086 15.1806 3.98573C14.4455 3.2506 13.5728 2.66746 12.6123 2.26961C11.6518 1.87176 10.6223 1.66699 9.58268 1.66699C7.48305 1.66699 5.46942 2.50107 3.98475 3.98573C2.50009 5.47039 1.66602 7.48403 1.66602 9.58366C1.66602 11.6833 2.50009 13.6969 3.98475 15.1816C5.46942 16.6662 7.48305 17.5003 9.58268 17.5003V17.5003Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
        </div>
        <div className='courses-group__wrap'>
          <div className='courses-group__subtitle'>{t('Start date')}</div>
          <div className='dropdown'>
            <div className='dropdown__btn'>
              <div className='calendar-input' style={{ backgroundColor: '#fff', borderRadius: 10 }}>
                <input
                  type='date'
                  placeholder='...gacha'
                  value={moment(date).format('YYYY-MM-DD')}
                  style={{ minWidth: '90%', fontSize: '0.7rem' }}
                />
                <a data-tooltip-id='calendar' data-some-relevant-attr={'end_date'}>
                  <CalendarFill />
                </a>
              </div>
            </div>
            {/* <ul class="dropdown__list">
							<li>
								some
							</li>
							<li>
								some
							</li>
							<li>
								some
							</li>
						</ul> */}
          </div>
        </div>
      </div>
      <ul className='courses-group__list'>
        <GetContainer
          url='student/course_parts'
          params={{ search: search, start_date: moment(date).format('YYYY-MM-DD') }}
        >
          {({ data }) => data?.data?.map((item: ICoursePart) => <GroupCourseCard item={item} key={item.slug} />)}
        </GetContainer>
      </ul>
      <Tooltip
        id='calendar'
        place='bottom'
        offset={20}
        style={{
          backgroundColor: 'white',
          color: 'black',
          boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
          zIndex: 9999
        }}
        clickable={true}
        openOnClick={true}
        render={({ content, activeAnchor }) => {
          return (
            <Calendar
              onChange={(e: any) => setDate(e)}
              value={date}
              minDate={new Date()}
              locale={i18n.language}
              // Oyning nomini chiqarish
              formatMonthYear={
                i18n?.language === 'uz'
                  ? (locale, date) => `${uzLocale.months[date.getMonth()]} ${date.getFullYear()}`
                  : undefined
              }
              formatMonth={
                i18n?.language === 'uz' ? (locale, date) => `${uzLocale.months[date.getMonth()]}` : undefined
              }
              // Hafta kunlarini qisqa shaklda chiqarish (Ya, Du, Se...)
              formatShortWeekday={
                i18n?.language === 'uz' ? (locale, date) => uzLocale.weekdaysShort[date.getDay()] : undefined
              }
              // Hafta kunlarini to'liq yozish (Yakshanba, Dushanba...)
              formatWeekday={
                i18n?.language === 'uz' ? (locale, date) => uzLocale.weekdaysLong[date.getDay()] : undefined
              }
            />
          )
        }}
      />
    </div>
  )
}
