import React from 'react'
import { ITariff } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

interface IProps {
  tariff: ITariff
}

function TariffCard(props: IProps) {
  const { i18n, t } = useTranslation()
  const { mutate } = useCustomMutation('student/buy/coins', 'post')

  return (
    <li className={`rates-item rates-item__${props?.tariff?.color}`}>
      <div className='rates-item__name'>{props?.tariff?.name?.[i18n.language]}</div>
      <div className='rates-item__coin'>
        <span>{props.tariff?.coin}</span> {t('Coin')}
      </div>
      <div className='rates-item__info'>
        {props.tariff?.options?.map(item => (
          <div>{item?.name?.[i18n?.language]}</div>
        ))}
      </div>
      <div className='rates-item__price'>
        <span>{props.tariff?.sum_string?.[i18n.language]}</span>
      </div>
      <button
        className='btn btn-lightgreen'
        onClick={() =>
          mutate(
            { tariff_id: props.tariff.id, coin: props.tariff.coin, sum: props.tariff.sum },
            { onSuccess: data => window.open(data?.data?.url, '_blank') }
          )
        }
      >
        {t('Buy')}
      </button>
    </li>
  )
}

export default TariffCard
