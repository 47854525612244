import React, { useContext, useState } from 'react'
import Calendar from '../../../../assets/img/icons/Calendar'
import Coin from '../../../../assets/img/icons/Coin'
import RequestModal from '../../../../components/request-modal'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'
import { ISchedule, ISubject, ITeacher } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { MEDIA_URL } from '../../../../configs/request'
import { useAuth } from '../../../../hooks/useAuth'
import { RandomContext } from '../../../../providers/RandomProvider'
import { isAfterDate } from '../../../../configs/helpers'

interface IProps {
  slot: ISchedule
  subject: ISubject
  teacher: ITeacher
}

function IndividualLessonItem(props: IProps) {
  const { mutate, isSuccess, error } = useCustomMutation('/student/slots/reserve', 'POST')
  const { i18n, t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { refresh } = useAuth()
  const { setRandom } = useContext(RandomContext)

  return (
    <div className='individual-item'>
      <div className='individual-item__head'>
        <div className='individual-item__ico'>
          <img src={MEDIA_URL + props.subject?.icon} alt='icon' />
        </div>
        <div className='individual-item__wrap'>
          <div className='individual-item__name'>{props.subject?.name?.[i18n.language]}</div>
          <div className='individual-item__price'>
            {/* <Coin /> */}
            {/* <span>{props.subject?} coin</span> */}
          </div>
        </div>
      </div>
      <div className='individual-item__date'>
        <Calendar />
        <span>{moment(props.slot?.date)?.format('ddd, MMM D, YYYY')}</span>
      </div>
      <div className='individual-item__time'>
        <Calendar />
        <span> {props.slot?.time}</span>
      </div>
      <div className='individual-item__user'>
        <img
          src={
            props.teacher?.photo ? MEDIA_URL + props.teacher?.photo : require('../../../../assets/img/mock/student.jpg')
          }
          alt='student'
        />
        <span>{`${props.teacher?.firstname} ${props.teacher?.lastname}`}</span>
      </div>
      <div className='individual-item__btn'>
        {isAfterDate(moment(`${props.slot?.date}T${props.slot?.time}`).subtract(24, 'hours').toLocaleString()) && (
          <button className='btn' onClick={() => setOpen(true)}>
            {t('Book lesson')}
          </button>
        )}
      </div>
      <RequestModal
        isOpen={open}
        close={() => {
          setOpen(false)
          setRandom(Math.random())
        }}
        title='Request individual title'
        error={error}
        isSuccess={isSuccess}
        description={
          <>
            <p>{props.subject?.name?.[i18n.language]}</p>
            <p>
              {moment(props.slot?.date)?.format('ddd, MMM D, YYYY')} {props.slot?.time}
            </p>
          </>
        }
        send={() =>
          mutate(
            {
              subject_id: props.subject?.id,
              slot_id: props.slot?.id
            },
            {
              onSuccess: () => {
                refresh()
              }
            }
          )
        }
        acceptBtnText='Yes, I will'
        cancelBtnText='No'
        successTitle='Request individual success title'
        successDesc='Request individual success desc'
        errorTitle='Request individual success title'
      />
    </div>
  )
}

export default IndividualLessonItem
