import GetContainer from '../../../components/get-container'
import { ITariff } from '../../../types/types'
import CouponForm from './components/CouponForm'
import TariffCard from './components/TariffCard'
import 'rc-slider/assets/index.css'
import PaymentCalc from './components/PaymentCalc'
import { useTranslation } from 'react-i18next'

export const Payment = () => {
  const { t } = useTranslation()
  return (
    <div>
      <GetContainer url='student/tariffs'>
        {({ data }) => (
          <ul className='rates-list'>
            {data?.data?.map((tariff: ITariff) => (
              <TariffCard tariff={tariff} key={tariff.id} />
            ))}
          </ul>
        )}
      </GetContainer>
      <div className='calc-card'>
        <div className='calc-content'>
          <div className='calc__title'>{t('Classes per week')}</div>
          <div className='calc__text'>
            {t(
              'Our free Starter Site plan is great for trying Webflow. Purchase a paid Site plan to publish, host, and unlock additional features.'
            )}
          </div>
        </div>
        <PaymentCalc />
      </div>
      <CouponForm />
    </div>
  )
}
