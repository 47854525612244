import React, { useContext, useState } from 'react'
import { ISlot } from '../../../../../types/types'
import { useTranslation } from 'react-i18next'
import { useCustomMutation } from '../../../../../hooks/useCustomMutation'
import { RandomContext } from '../../../../../providers/RandomProvider'
import RequestModal from '../../../../../components/request-modal'
import { MEDIA_URL } from '../../../../../configs/request'
import { isAfterDate, isWithin24Hours } from '../../../../../configs/helpers'

interface IProps {
  data: ISlot
}

function NearbyLessonCard(props: IProps) {
  const { t, i18n } = useTranslation()
  const { setRandom } = useContext(RandomContext)
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')

  const { mutate: enter, error, isSuccess } = useCustomMutation('/student/slots/enter', 'POST', name + props.data.id)
  const {
    mutate: cancel,
    error: cancelError,
    isSuccess: successCancel
  } = useCustomMutation('/student/slots/cancel', 'POST', name + props.data.id)

  return (
    <>
      {' '}
      <div className='lesson__img'>
        <img src={MEDIA_URL + props.data?.schedule?.group?.subject?.banner} alt='lesson' />
      </div>
      <div className='lesson-info'>
        <div className='lesson__name'>{props.data?.schedule?.lesson?.name?.[i18n.language]}</div>
        <div className='lesson__name'>
          {t('Type')}:{props.data?.schedule?.group?.type}
        </div>
        <div className='lesson__wrap'>
          <div className='lesson__time'>
            <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M19 15C16.79 15 15 16.79 15 19C15 19.75 15.21 20.46 15.58 21.06C15.9325 21.6525 16.4333 22.143 17.033 22.4831C17.6326 22.8233 18.3106 23.0014 19 23C20.46 23 21.73 22.22 22.42 21.06C22.79 20.46 23 19.75 23 19C23 16.79 21.21 15 19 15ZM21.07 18.57L18.94 20.54C18.8 20.67 18.61 20.74 18.43 20.74C18.24 20.74 18.05 20.67 17.9 20.52L16.91 19.53C16.7705 19.3889 16.6923 19.1984 16.6923 19C16.6923 18.8016 16.7705 18.6111 16.91 18.47C17.2 18.18 17.68 18.18 17.97 18.47L18.45 18.95L20.05 17.47C20.35 17.19 20.83 17.21 21.11 17.51C21.39 17.81 21.37 18.28 21.07 18.57Z'
                fill='#83878F'
              />
              <path
                d='M16.7502 3.56V2C16.7502 1.59 16.4102 1.25 16.0002 1.25C15.5902 1.25 15.2502 1.59 15.2502 2V3.5H8.75023V2C8.75023 1.59 8.41023 1.25 8.00023 1.25C7.59023 1.25 7.25023 1.59 7.25023 2V3.56C4.55023 3.81 3.24023 5.42 3.04023 7.81C3.02023 8.1 3.26023 8.34 3.54023 8.34H20.4602C20.7502 8.34 20.9902 8.09 20.9602 7.81C20.7602 5.42 19.4502 3.81 16.7502 3.56Z'
                fill='#83878F'
              />
              <path
                d='M20 9.83984H4C3.45 9.83984 3 10.2898 3 10.8398V16.9998C3 19.9998 4.5 21.9998 8 21.9998H12.93C13.62 21.9998 14.1 21.3298 13.88 20.6798C13.68 20.0998 13.51 19.4598 13.51 18.9998C13.51 15.9698 15.98 13.4998 19.01 13.4998C19.3 13.4998 19.59 13.5198 19.87 13.5698C20.47 13.6598 21.01 13.1898 21.01 12.5898V10.8498C21.0074 10.5828 20.9001 10.3274 20.7113 10.1385C20.5224 9.9497 20.2671 9.84245 20 9.83984ZM9.21 18.2098C9.02 18.3898 8.76 18.4998 8.5 18.4998C8.24 18.4998 7.98 18.3898 7.79 18.2098C7.61 18.0198 7.5 17.7598 7.5 17.4998C7.5 17.2398 7.61 16.9798 7.79 16.7898C7.89 16.6998 7.99 16.6298 8.12 16.5798C8.49 16.4198 8.93 16.5098 9.21 16.7898C9.39 16.9798 9.5 17.2398 9.5 17.4998C9.5 17.7598 9.39 18.0198 9.21 18.2098ZM9.21 14.7098L9.06 14.8298C9 14.8698 8.94 14.8998 8.88 14.9198C8.82 14.9498 8.76 14.9698 8.7 14.9798C8.63 14.9898 8.56 14.9998 8.5 14.9998C8.24 14.9998 7.98 14.8898 7.79 14.7098C7.61 14.5198 7.5 14.2598 7.5 13.9998C7.5 13.7398 7.61 13.4798 7.79 13.2898C8.02 13.0598 8.37 12.9498 8.7 13.0198C8.76 13.0298 8.82 13.0498 8.88 13.0798C8.94 13.0998 9 13.1298 9.06 13.1698L9.21 13.2898C9.39 13.4798 9.5 13.7398 9.5 13.9998C9.5 14.2598 9.39 14.5198 9.21 14.7098ZM12.71 14.7098C12.52 14.8898 12.26 14.9998 12 14.9998C11.74 14.9998 11.48 14.8898 11.29 14.7098C11.11 14.5198 11 14.2598 11 13.9998C11 13.7398 11.11 13.4798 11.29 13.2898C11.67 12.9198 12.34 12.9198 12.71 13.2898C12.89 13.4798 13 13.7398 13 13.9998C13 14.2598 12.89 14.5198 12.71 14.7098Z'
                fill='#83878F'
              />
            </svg>
            <span>{props.data?.date}</span>
            <div>{props.data?.time}</div>
          </div>
          <div className='lesson__links'>
            {isAfterDate(`${props?.data?.date}T${props?.data?.time}`) &&
              props.data?.schedule?.group?.type === 'individual' && (
                <a
                  onClick={() => {
                    setOpen(true)
                    setName('cancel')
                  }}
                >
                  {t('Cancel')}
                </a>
              )}
            <a
              className='blue'
              onClick={() => {
                setOpen(true)
                setName('enter')
              }}
            >
              {t('Enter')}
            </a>
          </div>
        </div>
      </div>
      <RequestModal
        isOpen={open}
        close={() => setOpen(false)}
        title={`Request ${name} title`}
        error={error || cancelError}
        isSuccess={isSuccess || successCancel}
        description={
          name === 'cancel' && isWithin24Hours(`${props.data?.date} ${props.data?.time}`) ? (
            <span className='danger-text'>
              {t('Less than 24 hours left until the lesson starts, coins will not be refunded!')}
            </span>
          ) : (
            <div />
          )
        }
        send={() => {
          if (name === 'enter') {
            enter(
              { slot_id: props.data.id },
              {
                onSuccess(data, variables, context) {
                  if (data?.data?.zoom_link) {
                    window.open(data?.data?.zoom_link, '_blank')
                  }
                  setRandom(Math.random())
                }
              }
            )
          } else {
            cancel(
              {
                schedule_id: props.data?.schedule_id
              },
              {
                onSuccess(data, variables, context) {
                  setRandom(Math.random())
                },
                onError: () => {}
              }
            )
          }
        }}
        acceptBtnText='Yes, I will'
        cancelBtnText='No'
        successTitle={`Request ${name} success title`}
        successDesc={`Request ${name} success desc`}
        errorTitle={`Request ${name} error title`}
      />
    </>
  )
}

export default NearbyLessonCard
