import React, { useMemo, useState } from 'react'
import Close from '../../../../assets/img/icons/Close'
import styles from './SidebarMenu.module.scss' // SCSS module import
import { useAuth } from '../../../../hooks/useAuth'
import { profileMenuData, sideMenuData, teachersMenuData } from '../../../../data/menuData'
import { useNavigate } from 'react-router-dom'

interface IProps {
  open: boolean
  close: () => void
}

function MobileMenu(props: IProps) {
  const [openMenu, setOpenMenu] = useState<string | null>(null) // Ochilgan
  const navigate = useNavigate()
  const auth = useAuth()
  const menuItems: {
    icon: React.JSX.Element
    path: string
    title: string
    children?: { icon: React.JSX.Element; path: string; title: string }[]
  }[] = useMemo(() => {
    if (auth?.user?.role === 'teacher') {
      return teachersMenuData
    } else {
      return [...profileMenuData, ...sideMenuData]
    }
  }, [auth?.user?.role])

  const handleMenuClick = (path: string) => {
    setOpenMenu(openMenu === path ? null : path) // Agar menyu ochilgan bo'lsa, yopiladi
  }
  const onNavigate = (path: string, role: 'student' | 'teacher', parent?: string) => {
    if (!parent && role === 'student') {
      return
    } else {
      navigate(`/${role}${role === 'student' ? (parent ? parent : '/home') : ''}${path}`)
      props.close()
    }
  }
  return (
    <div
      className={`mobile-menu ${props.open ? 'mobile-menu-open' : ''}`}
      style={{ display: props.open ? 'block' : 'none' }}
    >
      <div className='mobile-menu__wrap'>
        <div className='mobile-menu__head'>
          <div className='mobile-menu__close' onClick={props.close}>
            <Close/>
          </div>
          <a href={`/${auth?.user?.role}/home`} className='mobile-menu__logo' onClick={() => props.close()}>
            <img src={require('../../../../assets/img/logo.png')} alt='logo'/>
          </a>
        </div>
        <div className='mobile-menu__list'>
          <div className={styles.sidebar}>
            <ul>
            {menuItems.map(menu => (
                <li
                  key={menu.path}
                  className={`${styles.menuItem} ${openMenu === menu.path ? styles.open : ''}`}
                  onClick={() => onNavigate(menu?.path, auth?.user?.role || 'student')}
                >
                  {/* Asosiy menyu */}
                  <div
                      className={`${styles.menuTitle} ${openMenu === menu.path ? styles.active : ''}`}
                      onClick={() => handleMenuClick(menu.path)}
                  >
                    <span className={styles.icon}>{menu.icon}</span>
                    <span>{menu.title}</span>
                    {menu.children && <span className={styles.arrow}>{openMenu === menu.path ? '▲' : '▼'}</span>}
                  </div>

                  {/* Child menyu */}
                  {menu.children && openMenu === menu.path && (
                    <ul className={styles.childMenu}>
                      {menu.children.map(child => (
                        <li
                          key={child.path}
                          className={styles.childMenuItem}
                          onClick={() =>
                            onNavigate(child?.path, auth?.user?.role || 'student', `/${menu?.title.toLowerCase()}`)
                          }
                        >
                          <span className={styles.icon}>{child.icon}</span>
                          {child.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
