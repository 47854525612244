import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import GetContainer from '../../../components/get-container'
import { ILesson } from '../../../types/types'
import { useTranslation } from 'react-i18next'
import WithHTML from '../../../components/with-html'

export const LessonsPlan = () => {
  const params = useParams()
  let [searchParams, setSearchParams] = useSearchParams()
  const { i18n, t } = useTranslation()
  const location = useLocation()

  return (
    <GetContainer url={'teacher/lessons/' + params?.lesson_slug}>
      {({
        data
      }: {
        data: {
          data: ILesson
        }
      }) => (
        <>
          <ul className='breadcrumbs'>
            <li>
              <a href='/teacher/home'>
                <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002ZM12.75 18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18Z'
                    fill='#1F4F51'
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href='/teacher/lessons'>{t('Lesson plans')}</a>
            </li>
            <li>
              <a href={'/teacher/lessons/' + params?.subject_id}>
                {t('Subject')}: {data?.data?.unit?.grade?.subject?.name?.[i18n?.language]}
              </a>
            </li>
            <li>
              <a href={`/teacher/lessons/${params?.subject_id}/${params?.lesson_slug}?type=plan`} className='active'>
                {t('Lesson')}: {data?.data?.name?.[i18n?.language]}
              </a>
            </li>

            <li>
              <a href={location.pathname + '?type=homework'} className='active'>
                {searchParams?.get('type') === 'homework' ? data?.data?.homework?.name?.[i18n?.language] : ''}
              </a>
            </li>
          </ul>
          <div className='classes-head'>
            <li
              className={searchParams?.get('type') === 'plan' ? 'current' : ''}
              onClick={() => setSearchParams({ type: 'plan' })}
            >
              {t('Lesson plan')}
            </li>
            <li
              className={searchParams?.get('type') === 'homework' ? 'current' : ''}
              onClick={() => setSearchParams({ type: 'homework' })}
            >
              {t('Homework')}
            </li>
          </div>
          <div className='classes-single'>
            <div className='homework-main'>
              <div className='classes-single__title'>
                {searchParams?.get('type') === 'homework' ? data?.data?.homework?.name?.[i18n?.language] : ''}
              </div>
              <ul className='homework-info'>
                <li>
                  {t('Grade')} <span>{data?.data?.unit?.grade?.name?.[i18n?.language]}</span>
                </li>
                <li>
                  {t('Unit')} <span>{data?.data?.unit?.name?.[i18n?.language]}</span>
                </li>
                <li>
                  {t('Lesson')} <span>{data?.data?.name?.[i18n?.language]}</span>
                </li>
              </ul>
              <ul className='homework-list' style={{ maxWidth: '100%', overflow: 'auto' }}>
                <WithHTML
                  html={
                    searchParams?.get('type') === 'plan'
                      ? data?.data?.lesson_plan?.[i18n?.language]
                      : data?.data?.homework?.description?.[i18n?.language]
                  }
                />
              </ul>
            </div>
          </div>
        </>
      )}
    </GetContainer>
  )
}
