import { useTranslation } from 'react-i18next'
import GetContainerPagination from '../../../components/get-container-pagination'
import { ISlot } from '../../../types/types'
import moment from 'moment'
import { MEDIA_URL } from '../../../configs/request'
import { useLocation } from 'react-router-dom'

export const LessonHistory = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  return (
    <>
      <div className='history-table'>
        <div className='history-table__head'>
          <div>{t('Date')}</div>
          <div>{t('Subject')}</div>
          <div>{t('Type')}</div>
          <div>{t('teacher')}</div>
          <div>{t('Lesson')}</div>
          <div>{t('Homework')}</div>
        </div>
        <GetContainerPagination url='/student/slots' params={{ status: 'the_lesson_is_over' }}>
          {({ data }) =>
            data?.data?.map((item: ISlot) => (
              <div className='history-table__row'>
                <div className='history-table__date'>{`${item?.time} / ${moment(item?.date).format('DD.MM.YY')}`}</div>
                <div className='course-type'>
                  <span className='course-type__ico'>
                    <img src={MEDIA_URL + item?.schedule?.group?.subject?.icon} alt='icon' />
                  </span>
                  <span>{item?.schedule?.group?.subject?.name?.[i18n.language]}</span>
                </div>
                <div className='history-table__type'>{t(item?.schedule?.group?.type)}</div>
                <div className='history-table__teacher'>
                  <img
                    src={
                      item?.schedule?.group?.teacher?.photo
                        ? MEDIA_URL + item?.schedule?.group?.teacher?.photo
                        : require('../../../assets/img/mock/student.jpg')
                    }
                    alt='student'
                  />
                  <span>{`${item?.schedule?.group?.teacher?.firstname} ${item?.schedule?.group?.teacher?.lastname}`}</span>
                </div>
                <div className='history-table__work'>{item?.schedule?.lesson?.name?.[i18n.language]}</div>
                <div className='history-table__homework'>
                  <span className={!item?.schedule?.student_homework ? 'empty' : ''}>
                    <a href={location?.pathname + '/' + item?.id}>
                      {item?.schedule?.student_homework ? t('Homework') : t('homework is not completed')}
                    </a>
                  </span>
                  {!!item?.schedule?.student_homework?.file && (
                    <span className={!item?.schedule?.student_homework ? 'empty' : ''}>
                      <a href={MEDIA_URL + item?.schedule?.student_homework?.file} target='_blank' rel='noreferrer'>
                        {item?.schedule?.student_homework ? t('Homework&Complete') : '---'}
                      </a>
                    </span>
                  )}
                </div>
              </div>
            ))
          }
        </GetContainerPagination>
      </div>
    </>
  )
}
