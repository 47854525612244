import React from 'react'
import Form from '../../../../components/form'
import CustomInput from '../../../../components/form/custom-input'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { useAuth } from '../../../../hooks/useAuth'

const validationSchemaPassword = Yup.object().shape({
  code: Yup.string().required('This field is required!')
})
const defaultValue = {
  code: ''
}

function CouponForm() {
  const { t } = useTranslation()
  const { refresh } = useAuth()

  return (
    <Form
      url='student/coupons/apply'
      initialValues={defaultValue}
      validationSchema={validationSchemaPassword}
      method='POST'
      onSuccess={() => {
        toast.success('Coupon applied successfully!')
        refresh()
      }}
    >
      {({ form, handleFinish, createInfo }) => {
        const {
          control,
          formState: { errors },
          handleSubmit,
          watch
        } = form
        return (
          <div className='settings-form'>
            <div className='settings-wrap' style={{ alignItems: 'center' }}>
              <div className='settings-item'>
                <CustomInput
                  control={control}
                  name='code'
                  label='Coupon code'
                  placeholder='Coupon code'
                  error={errors?.code}
                />
              </div>
              <div className='settings-item'>
                <button
                  disabled={!watch('code')}
                  onClick={handleSubmit(data => handleFinish(data))}
                  className='settings-btn btn btn-orange'
                >
                  {t('Submit')}
                </button>
              </div>
            </div>
          </div>
        )
      }}
    </Form>
  )
}

export default CouponForm
