import React from 'react'
import { ISubject } from '../../../../types/types'
import { MEDIA_URL } from '../../../../configs/request'
import { useTranslation } from 'react-i18next'
import TeacherCalendar from '../../teachers/component/teacher-calendar'

interface IProps {
  item: ISubject
  teacherId: string
}

function TeacherSubject(props: IProps) {
  const { i18n } = useTranslation()
  return (
    <li className='teachers-course'>
      <div className='teachers-course__info'>
        <div className='course__head'>
          <img src={MEDIA_URL + props.item?.icon} alt={props?.item?.name?.[i18n.language]} />
        </div>
        <div className='course__name'>{props?.item?.name?.[i18n.language]}</div>

        <div className='teachers-course__text'>{props?.item?.title?.[i18n.language]}</div>
        {/* <a href='#' className='teachers-course__link btn btn-lightblue'>
              {t('Book class')}
            </a> */}
      </div>
      <div className='teachers-course__calendar'>
        <TeacherCalendar teacherId={props?.teacherId} subject={props.item} />
      </div>
    </li>
  )
}

export default TeacherSubject
