import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { IMenu } from '../..'
import { useTranslation } from 'react-i18next'

function SideMenuItem(props: IMenu) {
  const location = useLocation()
  const { t } = useTranslation()
  return (
    <li>
      <NavLink
        to={`/${location.pathname?.split('/')?.[1]}${props.path}`}
        className={location?.pathname?.split('/')?.[2] === props.path?.split('/')?.[1] ? 'active' : ''}
      >
        {props.icon}
        <span>{t(props.title)}</span>
      </NavLink>
    </li>
  )
}

export default SideMenuItem
