import React from 'react'
import { ITeacher } from '../../../../../types/types'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../../../../configs/request'
import TeacherCalendar from '../teacher-calendar'
import { useNavigate } from 'react-router-dom'

interface IProps {
  item: ITeacher
}

const TeachersEach = (props: IProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  return (
    <li className='teachers-item'>
      <div className='teachers-item__wrap'>
        <div className='teachers-item__img'>
          <img
            src={
              props.item?.photo ? MEDIA_URL + props?.item?.photo : require('../../../../../assets/img/mock/teacher.png')
            }
            alt='teacher'
          />
        </div>
        <a href={`/student/school/teachers/` + props.item?.uuid} className='teachers-item__btn btn btn-lightblue'>
          {t('View profile')}
        </a>
      </div>
      <div className='teachers-item__wrap'>
        <div className='teachers-item__name'>{`${props.item?.firstname} ${props.item?.lastname}`}</div>
        <div className='teachers-item__pos'>{t(props.item?.role)}</div>
        <div className='teachers-item__desc'>{props.item?.info?.bio}</div>
          <div className='teachers-item__courses'>
              {props?.item?.subjects
                  ?.filter(subject => subject?.type === 'individual') // Оставляем только "individual"
                  ?.map(subject => (
                      <div className='course-type' key={subject?.slug}>
        <span className='course-type__ico'>
          <img src={MEDIA_URL + subject?.icon} alt={subject?.name?.[i18n?.language]} width={'80%'}/>
        </span>
                          <span>{subject?.name?.[i18n?.language]}</span>
                      </div>
                  ))}
          </div>
      </div>
        <div className='teachers-item__wrap' onClick={() => navigate(`/student/school/teachers/` + props.item?.uuid)}>
            <TeacherCalendar teacherId={props.item?.uuid}/>
        </div>
    </li>
  )
}

export default TeachersEach
