import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGrade, ISubject } from '../../../types/types'
import GetContainerPagination from '../../../components/get-container-pagination'
import { useNavigate, useParams } from 'react-router-dom'

export const Grades = () => {
  const params = useParams()

  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  return (
    <GetContainerPagination url='teacher/grades' params={{ subject_id: params?.subject_id }}>
      {({ data }) => (
        <>
          <ul className='breadcrumbs'>
            <li>
              <a href='/teacher/home'>
                <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002ZM12.75 18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18Z'
                    fill='#1a3b3d'
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href='/teacher/lessons'>{t('Lessons plans')}</a>
            </li>
            <li>
              <a href={'/teacher/lessons/' + params?.subject_id} className='active'>
                {t('Subject')}: {data?.data?.[0]?.unit?.grade?.subject?.name?.[i18n?.language]}
              </a>
            </li>
          </ul>

          <div className='lessons-list'>
            <div className='history-table'>
              <div className='history-table__head'>
                <div>{t('Name')}</div>
                <div>{t('Age')}</div>
              </div>
              {data?.data?.map((grade: IGrade) => (
                <div className='history-table__row' key={grade.slug}>
                  <div className='history-table__item'>{grade?.name?.[i18n.language]}</div>
                  <div className='history-table__item'>{grade?.age_recommendation?.[i18n.language]}</div>
                  <div className='history-table__item'>
                    <button
                      className='btn'
                      onClick={() => navigate(`/teacher/lessons/${params?.subject_id}/${grade?.id}`)}
                    >
                      <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.32919 17.9996H14.6692C17.9892 17.9996 19.3392 15.6496 17.6892 12.7796L16.9492 11.4996C16.7692 11.1896 16.4392 10.9996 16.0792 10.9996H7.91919C7.55919 10.9996 7.22919 11.1896 7.04919 11.4996L6.30919 12.7796C4.65919 15.6496 6.00919 17.9996 9.32919 17.9996ZM8.79119 9.99859H15.2212C15.6112 9.99859 15.8512 9.57859 15.6512 9.24859L15.0112 8.14859C13.3612 5.27859 10.6412 5.27859 8.99119 8.14859L8.35119 9.24859C8.16119 9.57859 8.40119 9.99859 8.79119 9.99859Z'
                          fill='white'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </GetContainerPagination>
  )
}
