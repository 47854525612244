import React, { Fragment, useState } from 'react'
import { ISchedule } from '../../../../types/types'
import Form from '../../../../components/form'
import * as Yup from 'yup'
import CustomCheckbox from '../../../../components/form/custom-checkbox'
import { useTranslation } from 'react-i18next'
import CustomInput from '../../../../components/form/custom-input'
import CustomTextarea from '../../../../components/form/custom-textarea'
import { Controller } from 'react-hook-form'
// @ts-ignore
import AsyncSelectController from '../../../../components/form/custom-select/AsyncSelectController'
import GetContainer from '../../../../components/get-container'
import RequestModal from '../../../../components/request-modal'
import { useNavigate } from 'react-router-dom'

const validationSchema = Yup.object().shape({
  schedule_id: Yup.number().required('Phone is required'), //required
  student_id: Yup.number().required('Phone is required'), //required
  visit: Yup.boolean().required('Phone is required'), //required
  next_lesson_id: Yup.number().required('Phone is required'), //required
  status: Yup.string().required('Phone is required'), //required [next, repeat, manual]
  report: Yup.string().required('Phone is required') //required
})

interface IProps {
  data: ISchedule
}

function IndividualCompleted(props: IProps) {
  const { t, i18n } = useTranslation()
  const renderValues = (data: ISchedule) => {
    return {
      schedule_id: data?.id, //required
      student_id: data?.group?.students?.[0]?.id, //required
      message: '', //nullable (if visit == true -> message:required)
      visit: true, //required
      status: 'next', //required [next, repeat, manual]
      report: '', //required,
      grade_id: data?.lesson?.unit?.grade_id, //required
      last_lesson_id: data?.lesson_id //required
    }
  }
  const [modal, setModal] = useState({
    open: false,
    isError: false,
    isSuccess: false,
    error: null
  })
  const navigate = useNavigate()
  return (
    <Fragment>
      <Form
        validationSchema={validationSchema}
        initialValues={renderValues(props.data)}
        url='/teacher/group_schedules/individual/completed'
        method='POST'
        onSuccess={() => setModal({ isSuccess: true, isError: false, error: null, open: true })}
        onError={error => setModal({ isSuccess: false, isError: true, error: error, open: true })}
      >
        {({ form, handleFinish, createInfo, getInfo }) => {
          const {
            control,
            handleSubmit,
            formState: { errors },
            setValue,
            watch
          } = form
          return (
            <div style={{ marginTop: '1rem' }}>
              <div className='homework-other'>
                <div className='homework-other__item'>
                  <div className='homework__title'>{`${props.data?.group?.students?.[0]?.firstname} ${props.data?.group?.students?.[0]?.lastname}`}</div>
                  <div className='homework-other__wrap'>
                    <CustomCheckbox control={control} name='visit' label='Visit' error={errors?.visit} />
                  </div>
                </div>
                <div className='homework-other__item'>
                  <div className='homework__title'>{t('Review')}</div>
                  <div className='homework-other__wrap'>
                    <div className='homework__text'>
                      {t(
                        'This message will get sent to your student by email. Please write your message in complete sentences. Please do not comment anything negative, encourage your student. Double check your spelling before submitting.'
                      )}
                    </div>
                    <div className='homework__input'>
                      <CustomInput
                        control={control}
                        name='message'
                        label=''
                        error={errors?.review}
                        disabled={!watch('visit')}
                        placeholder={t('Did well with')}
                      />
                    </div>
                    <div className='homework__input'>
                      <CustomTextarea
                        control={control}
                        name='report'
                        label='Report'
                        error={errors?.review}
                        placeholder={'Report'}
                      />
                    </div>
                  </div>
                </div>
                <GetContainer
                  onSuccess={data => {
                    console.log(data, 'data')
                    // const lessonIndex = lessons?.data?.findIndex(
                    //   (lesson: ILesson) => lesson?.id === props?.data?.lesson_id
                    // )
                    // const next_lesson: ILesson = lessons?.data?.[lessonIndex + 1]
                    setValue('next_lesson_id', data?.data?.id)
                  }}
                  url={
                    getInfo?.isLoading || getInfo?.isFetching
                      ? ''
                      : `/teacher/students/${props.data?.group?.students?.[0]?.uuid}/progress/${props.data?.group?.subject?.id}`
                  }
                >
                  {({ data: progress }) => (
                    <div className='homework-other__item'>
                      <div className='homework__title'>{t('Next lesson')}</div>
                      <div className='homework-other__wrap'>
                        <Controller
                          name={'status'}
                          control={control}
                          render={({ field }) => (
                            <div className='homework-choose'>
                              <label className='homework__check'>
                                <input
                                  type='radio'
                                  value={'next'}
                                  name='status'
                                  checked={field.value === 'next'}
                                  onChange={e => {
                                    if (e.target?.checked) {
                                      field.onChange('next')
                                      setValue('next_lesson_id', progress?.data?.id)
                                      setValue('grade_id', progress?.data?.unit?.grade_id)
                                    }
                                  }}
                                />
                                <span>{t('Next lesson')}</span>
                              </label>
                              <label className='homework__check'>
                                <input
                                  type='radio'
                                  value={'repeat'}
                                  name='status'
                                  checked={field.value === 'repeat'}
                                  onChange={e => {
                                    if (e.target?.checked) {
                                      field.onChange('repeat')
                                      setValue('next_lesson_id', props?.data?.lesson_id)
                                      setValue('grade_id', props?.data?.lesson?.unit?.grade_id)
                                    }
                                  }}
                                />
                                <span>{t('Repeat this lesson')}</span>
                              </label>
                              <label className='homework__check'>
                                <input
                                  type='radio'
                                  value={'manual'}
                                  name='status'
                                  checked={field.value === 'manual'}
                                  onChange={e => {
                                    if (e.target?.checked) {
                                      field.onChange('manual')
                                    }
                                  }}
                                />
                                <span>{t('Manual selection')}</span>
                              </label>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}
                </GetContainer>
                <div className='homework-other__item'>
                  <div className='homework__title'>{t('Subject')}</div>
                  <div className='homework-other__wrap' style={{ flex: 1 }}>
                    <div className='homework__input'>
                      <AsyncSelectController
                        control={control}
                        name='grade_id'
                        getLabel={data => data?.name?.[i18n.language]}
                        getValue={data => data?.id}
                        params={{ subject_id: props.data?.group?.subject?.id }}
                        url='/teacher/grades'
                      />
                    </div>
                    <div className='homework__input'>
                      <AsyncSelectController
                        control={control}
                        name='next_lesson_id'
                        disabled={watch('status') !== 'manual'}
                        getLabel={data => data?.name?.[i18n.language]}
                        getValue={data => data?.id}
                        params={{ grade_id: watch('grade_id'), subject_id: props.data?.group?.subject?.id }}
                        url='/teacher/lessons'
                      />
                    </div>
                  </div>
                </div>
                <div className='homework-other__item'>
                  <div className='homework__title'></div>
                  <div className='homework-other__wrap'>
                    <button className='homework__btn btn' onClick={handleSubmit(data => handleFinish(data))}>
                      {t('Submit')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Form>
      <RequestModal
        isOpen={modal?.open}
        close={() => {
          setModal({ open: false, isError: false, isSuccess: false, error: null })
          navigate(-1)
        }}
        title='Request subjects title'
        error={modal?.error}
        isSuccess={modal.isSuccess}
        description={
          <>
            {/* <p>
                    {data?.data
                      ?.filter((subject: ISubject) =>
                        form.watch('subject_ids')?.some((id: number) => id === subject?.id)
                      )
                      ?.map((subject: ISubject) => subject?.name?.[i18n.language])
                      .join(', ')}
                  </p> */}
          </>
        }
        send={() => {}}
        acceptBtnText=''
        cancelBtnText=''
        successTitle='Request individual complete success title'
        successDesc='Request individual complete success desc'
        errorTitle='Request individual complete success title'
      />
    </Fragment>
  )
}

export default IndividualCompleted
