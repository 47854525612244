import moment from 'moment'

interface IError {
  timestamp: string
  status: boolean
  message: string
  data: {
    [key: string]: [string]
  }
}

export const getErrors = (error: IError) => {
  if (error?.message === 'error.400') {
    if (Object.keys(error?.data)?.length) {
      let errors: any = error?.data

      Object.keys(error?.data)?.forEach(key => {
        errors = {
          ...errors,
          [key]: {
            message: error?.data?.[key]?.[0],
            ref: { name: key },
            type: 'request'
          }
        }
      })
      return errors
    } else {
      return {}
    }
  } else {
    return {}
  }
}

export const isAfterDate = (date: string | Date): boolean => {
  console.log('date', date)
  console.log('isAfter', moment(date).isAfter(moment()))
  return moment(date).isAfter(moment())
}

export function isWithin24Hours(targetTime: string) {
  let now = moment() // Hozirgi vaqt
  let target = moment(targetTime, 'YYYY-MM-DD HH:mm:ss') // Berilgan vaqt

  let diffHours = target.diff(now, 'hours') // Soat farqini olish

  return diffHours <= 24 && diffHours > 0 // 0 dan katta va 24 soatdan kichik bo‘lsa true qaytaradi
}
