import { Fragment } from 'react'
import GetContainer from '../../../components/get-container'
import { useParams } from 'react-router-dom'
import { IReview } from '../../../types/types'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../../configs/request'
import CalendarFill from '../../../assets/img/icons/CalendarFill'
import moment from 'moment'

export const TeacherReviewsSingle = () => {
  const { id } = useParams()
  const { i18n, t } = useTranslation()
  return (
    <div className='reviews-single'>
      <GetContainer url='/student/reviews' params={{ subject_id: id }}>
        {({ data }: { data: { data: IReview[] } }) => (
          <Fragment>
            <div className='reviews-single__head'>
              <a href='./' className='reviews-single__back'>
                <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M9.57 18.0703L3.5 12.0003L9.57 5.93031M20.5 12.0003L3.67 12.0003'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    strokeMiterlimit={10}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </a>
              {/*  */}

              <div className='course-type'>
                <span className='course-type__ico'>
                  <img src={MEDIA_URL + data?.data?.[0]?.schedule?.group?.subject?.icon} alt='icon' />
                </span>
                <span>{data?.data?.[0]?.schedule?.group?.subject?.name?.[i18n.language]}</span>
              </div>
            </div>

            <ul className='reviews-single__list'>
              {data?.data?.map((item: IReview, index: number) => (
                <li key={item.id}>
                  <img src={MEDIA_URL + item?.teacher?.photo} alt='name' />
                  <div className='reviews-single__main'>
                    <p>
                      {item.teacher?.firstname} {item.teacher?.lastname}
                    </p>
                    <p>{item.message}</p>
                    <div className='reviews-single__bot'>
                      {data?.data?.length - 1 === index && !item?.reply && (
                        <a href={window?.location?.pathname + '/' + item?.schedule_id} className='btn btn-trans'>
                          {t('Leave feedback')}
                        </a>
                      )}
                      <div className='reviews-single__date'>
                        <CalendarFill />
                        <span>{moment(item?.created_at || item?.updated_at).format('DD.MM.YYYY HH:mm:ss')}</span>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </Fragment>
        )}
      </GetContainer>
    </div>
  )
}
