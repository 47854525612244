import { useTranslation } from 'react-i18next'
import GetContainerPagination from '../../../components/get-container-pagination'

export const Invite = () => {
  const { i18n, t } = useTranslation()
  return (
    <div className='coin-info'>
      <GetContainerPagination url='/student/invited/friends'>
        {({ data }) => (
          <div className='coin-table table'>
            <table>
              <tbody>
                <tr className='head'>
                  <td>{t('Date')}</td>
                  <td>{t('First name')}</td>
                  <td>{t('Last name')}</td>
                </tr>
                {data?.data?.map((item: any) => (
                  <tr key={item?.id}>
                    <td>{item?.created_at}</td>
                    <td>{item?.firstname}</td>
                    <td>{item?.lastname}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </GetContainerPagination>
    </div>
  )
}
