import { useContext } from 'react'
import { TaklifSlider } from './components/taklifSlider'
import { YaqinSlider } from './components/yaqinSlider'
import { AuthContext } from '../../../context/AuthContext'
import { useTranslation } from 'react-i18next'
export const Home = () => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  return (
    <>
      <YaqinSlider onClick={() => {}} />

      <div className='home-welcome'>
        <div className='home-welcome__content'>
          <div className='home-welcome__title'>
            {t('Welcome')} <strong>{user?.firstname}!</strong>
          </div>
          <div className='home-welcome__text'>
            {t('This Challenge Describes The Class Placement, Contents Of Each Course')}
          </div>
          <div className='home-welcome__links'>
            <a href='/student/school/courses' className='blue'>
              {t('List of courses')}
            </a>
            <a href='/student/support/how-to-use'>{t('How to use')}</a>
          </div>
        </div>
        <div className='home-welcome__img'>
          <img src={require('../../../assets/img/welcome.jpg')} alt='welcome' />
        </div>
      </div>

      <TaklifSlider />
    </>
  )
}
