import React, { useContext } from 'react'
import { INotification } from '../../../../types/types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL, request } from '../../../../configs/request'
import { useQuery } from '@tanstack/react-query'
import { RandomContext } from '../../../../providers/RandomProvider'
interface IProps {
  item: INotification
}
function NotificationCard(props: IProps) {
  const { i18n, t } = useTranslation()
  const { setRandom } = useContext(RandomContext)
  const { refetch } = useQuery({
    queryKey: ['notifications', props.item?.id],
    queryFn: async () => {
      const response = await request({ url: `/student/notifications/${props.item?.id}/read`, method: 'get' }).finally(
        () => setRandom(Math.random())
      )
      return response.data
    },
    enabled: false
  })

  return (
    <div>
      <li className={`messages-nots__item ${props.item?.read ? '' : 'new'}`}>
        <div className='messages-nots__wrap'>
          <div className='messages-nots__name'>{props?.item?.title?.[i18n.language]}</div>
          <div className='messages-nots__text'>{props?.item?.message?.[i18n.language]}</div>
          <div className='messages-nots__time'>
            <span>{moment(props.item?.created_at).format('YYYY-MM-DD')}</span>
            <span>{moment(props.item?.created_at).format('HH:mm')}</span>
          </div>
          <button onClick={() => refetch()} className='btn'>
            {t('Mark as read')}
          </button>
        </div>
        <div className='messages-nots__img'>
          <img src={MEDIA_URL + props.item?.photo} alt='Notification' />
        </div>
      </li>
    </div>
  )
}

export default NotificationCard
