import React, { useState } from 'react'
import GetContainer from '../../../../components/get-container'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useTranslation } from 'react-i18next'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

function PaymentCalc() {
  const [value, setValue] = useState(0)
  const { t } = useTranslation()
  const { mutate } = useCustomMutation('student/buy/coins', 'post')

  return (
    <div className='calc-wrap'>
      <div className='calc-input'>
        <div className='calc-input__coin'>{t('Coin')}</div>
        <div className='calc-input__input'>
          <Slider marks={{}} min={0} max={9} value={value} onChange={e => setValue(Number(e))} />
        </div>
        <div className='calc-input__values'>
          <span className={`${value === 0 ? 'active' : ''}`}>100</span>
          <span className={`${value === 1 ? 'active' : ''}`}>200</span>
          <span className={`${value === 2 ? 'active' : ''}`}>300</span>
          <span className={`${value === 3 ? 'active' : ''}`}>400</span>
          <span className={`${value === 4 ? 'active' : ''}`}>500</span>
          <span className={`${value === 5 ? 'active' : ''}`}>600</span>
          <span className={`${value === 6 ? 'active' : ''}`}>700</span>
          <span className={`${value === 7 ? 'active' : ''}`}>800</span>
          <span className={`${value === 8 ? 'active' : ''}`}>900</span>
          <span className={`${value === 9 ? 'active' : ''}`}>1000</span>
        </div>
      </div>

      <GetContainer url='/student/get/rate' onSuccess={data => console.log(data)}>
        {({ data }) => (
          <div className='calc-info'>
            <div className='calc-info__price'>
              <div>
                <span>{(value + 1) * 100 * data?.data?.sum}</span>
              </div>

              {/* <div>
                    <span>100</span> ming
                  </div> */}
              <div className='som'>{t('Sum')}</div>
            </div>
            <button
              className='btn btn-lightgreen'
              onClick={() =>
                mutate(
                  { tariff_id: null, coin: (value + 1) * 100, sum: (value + 1) * 100 * data?.data?.sum },
                  { onSuccess: data => window.open(data?.data?.url, '_blank') }
                )
              }
            >
              {t('Buy')}
            </button>
          </div>
        )}
      </GetContainer>
    </div>
  )
}

export default PaymentCalc
